import { memo } from "react";
import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import ".././inner.css";
import { Link } from "react-router-dom";

function Hospitalinfo() {
  return (
    <>
      <section>
        <div className="container">
          <div className="row">
            <div className="col-md-6 hospalin">
              <div className="hospitalin">
                <h6>ABOUT US</h6>
                <h1>Ultimate Health Care and expertize</h1>
                <p>
                  Viksha comes with state-of-the-art world class facilities and
                  is a multispecialty clinic situated in the 95,15th Main, 17th
                  Cross Rd, 5th Phase, J. P. Nagar, Bengaluru, Karnataka. We at
                  Viksha are making the best of the exceptionally talented
                  specialists and the latest equipment to provide you the best
                  of medical facilities to ensure best health for you. Visit us
                  today for the best Best multispecialty hospital in Jayanagar
                </p>
                <h2 style={{ fontSize: "0px" }}>
                  Best multispecialty hospital in JP Nagar
                </h2>
                <div>
                  <div className="hospbar"></div>
                  <Link to="/about" className="hoslink">
                    <h6>MORE ABOUT US</h6>
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-md-6 hosimg">
              <img src="../photo/hospital.jpg" alt="" />
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
export default memo(Hospitalinfo);

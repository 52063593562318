import { memo, useContext } from "react"
import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css"
import ".././inner.css"
import { Link } from "react-router-dom"
import { LazyLoadImage } from "react-lazy-load-image-component"
import { data1 } from "../../App"

function Orthopedicser(){
    const setServiceseta=useContext(data1)
    const menu=[
        {
            img:"../photo/knee.png",
            name:"Knee And Hip Replacement Surgery Primary And Revision",
            text:"Knee and hip replacement surgeries are common procedures used to treat severe joint pain and mobility issues.",
            to:"orthoservices",
            toa:"/service/kneeAndHip"
        },
        {
            img:"../photo/osteology.png",
            name:"Bone Fracture Treatment Surgical And Conservative Treatment",
            text:"Bone fractures can be treated using surgical or conservative (non-surgical) methods, depending on the type and severity of the fracture.",
            to:"orthoservices",
            toa:"/service/kneeAndHip"
        },
        {
            img:"../photo/first-aid-bag.png",
            name:"Sport Medicine",
            text:"Sports medicine is a branch of medicine that focuses on the prevention, diagnosis, treatment, and rehabilitation of injuries and conditions related to sports and exercise.",
            to:"orthoservices",
            toa:"/service/sportMedicine"
        },
        {
            img:"../photo/arthritis.png",
            name:"Arthroscopic Acl And menicus Repair Reconstruction",
            text:"Arthroscopic ACL (Anterior Cruciate Ligament) and meniscus repair or reconstruction are common procedures performed in sports medicine to address injuries to the knee joint. ",
            to:"orthoservices",
            toa:"/service/arthroscopicAcl"
        },
        {
            img:"../photo/orthopedic.png",
            name:"Arthroscopic Shoulder Surgeries",
            text:"Arthroscopic shoulder surgery is a minimally invasive surgical procedure that is commonly used to diagnose and treat various shoulder conditions.",
            to:"orthoservices",
            toa:"/service/arthroscopicShoulder"
        },
        {
            img:"../photo/heavy.png",
            name:"Back Pain And Disc Related Diseases Treatment",
            text:"Back pain and disc-related diseases can be treated through various approaches, depending on the severity and specific condition.",
            to:"orthoservices",
            toa:"/service/backPain"
        },
    ]
    return (<>
    <section className="sectionmar orthseec">
        <div className="container">
            <div className="row orthocen">
                <h5>Orthopedic Services</h5>
                <h1>Viksha Health Care</h1>
                <h6>We give comprehensive orthopaedic treatments using the most advanced <br className="orthobr"></br>technologies at a competitive price with the highest level of care.</h6>
            </div>
            <div className="row">
                {
                    menu.map((elem)=>{
                        const {img, name, to, text,toa}=elem
                        return (<>
                        <div className="col-lg-4 col-md-6 othoserbox">
                    <div className="orthocon">
                        <div className="ortobarcon">
                            <div className="orthobaron"></div>
                            <div className="orthosqu"></div>
                        </div>
                        <div className="innerorthocon">
                            <div className="orthohe">
                                <div className="addresicon">
                                    <LazyLoadImage src={img} alt="" />
                                </div>
                                <div className="orthosenam">
                                    <h6>{name}</h6>
                                    <p>{text}</p>
                                </div>
                            </div>
                            <Link onClick={()=>{
                                        setServiceseta(name)
                                      }} to={toa} className="orthoread mt-2">
                                    <h6>Read More</h6>
                            </Link>
                        </div>
                        <div className="ortobarcon">
                            <div className="orthosqu"></div>
                            <div className="orthosqu"></div>
                        </div>
                    </div>
                </div>
                        </>)
                    })
                }
            </div>
        </div>
    </section>
    </>)
}
export default memo(Orthopedicser);
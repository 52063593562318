import { memo, useEffect } from "react";
import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import ".././inner.css";
import { LazyLoadImage } from "react-lazy-load-image-component";

function HighRiskObstetric() {
  useEffect(() => {
    document.title = "High Risk pregnancy doctor in Bannerghatta main road";
    const newMetaDescription =
      " Looking for High Risk pregnancy doctor in Bannerghatta main road? Then look no fur & visit us today.";
    const metaTag = document.querySelector('meta[name="description"]');
    if (metaTag) {
      metaTag.setAttribute("content", newMetaDescription);
    }
  }, []);
  return (
    <>
      <h2 style={{ fontSize: "0px" }}>
        High Risk pregnancy doctor in Bannerghatta main road
      </h2>
      <div className="serinfobox">
        <div className="serinfoimg">
          <LazyLoadImage src="../photo/sern.jpg" />
        </div>
        <div className="textareaofser">
          <h4>High Risk Obstetric Cases</h4>
          <p>
            High-risk obstetric cases refer to pregnancies that have an
            increased likelihood of complications or adverse outcomes for the
            mother, the fetus, or both. These cases require specialized care and
            management to ensure the best possible outcomes. Looking for High
            Risk pregnancy doctor in Bannerghatta main road? Then look no fur &
            visit us today.
          </p>
          <h5>1. Maternal age:</h5>
          <h6>Advanced maternal age: </h6>
          <p>
            Pregnancies in women who are 35 years or older are considered high
            risk, as they have an increased risk of pregnancy complications,
            such as gestational diabetes, hypertension, chromosomal
            abnormalities in the fetus, and preterm birth.
          </p>
          <h6>Teenage pregnancies:</h6>
          <p>
            Pregnancies in adolescents younger than 20 years are also considered
            high risk due to the increased likelihood of preterm birth, low
            birth weight, and inadequate prenatal care.
          </p>
          <h5>2. Pre-existing medical conditions:</h5>
          <h6>Diabetes:</h6>
          <p>
            Women with pre-existing diabetes (type 1 or type 2) have a higher
            risk of pregnancy complications, including birth defects,
            preeclampsia, preterm birth, and macrosomia (a large baby).
          </p>
          <h6>Hypertension:</h6>
          <p>
            Chronic hypertension or high blood pressure before pregnancy can
            increase the risk of preeclampsia, placental abruption, fetal growth
            restriction, and preterm birth.
          </p>
          <h6>Autoimmune disorders:</h6>
          <p>
            Conditions like lupus, rheumatoid arthritis, or antiphospholipid
            syndrome can increase the risk of complications such as
            preeclampsia, preterm birth, and fetal growth restriction.
          </p>
          <h5>3. Multiple pregnancies:</h5>
          <p>
            Twins, triplets, or higher-order multiples are considered high risk
            due to the increased likelihood of premature birth, low birth
            weight, gestational diabetes, preeclampsia, and other complications.
          </p>
          <h5>4. Previous pregnancy complications:</h5>
          <p>
            Women who have experienced previous complications such as preterm
            birth, preeclampsia, placental abruption, or fetal growth
            restriction are at a higher risk of recurrence in subsequent
            pregnancies.
          </p>
          <h5>5. Preterm labor or birth history:</h5>
          <p>
            Women who have previously delivered preterm or have a history of
            preterm labor are at increased risk of recurrence in future
            pregnancies.
          </p>
          <h5>6. Placenta-related complications:</h5>
          <h6>Placenta previa:</h6>
          <p>
            When the placenta partially or completely covers the cervix,
            increasing the risk of bleeding and the need for a cesarean section.
          </p>
          <h6>Placental abruption:</h6>
          <p>
            Premature separation of the placenta from the uterus, which can
            cause heavy bleeding and compromise the fetus's oxygen supply.
          </p>
          <p>
            In high-risk obstetric cases, specialized care is provided by
            obstetricians with expertise in managing complex pregnancies. Close
            monitoring, specialized testing, and interventions may be required
            to mitigate risks and ensure the best possible outcomes for both the
            mother and the baby. Care is often coordinated with a
            multidisciplinary team, including maternal-fetal medicine
            specialists, neonatologists, genetic counselors, and other
            healthcare professionals to provide comprehensive care and support
            throughout the pregnancy.
          </p>
        </div>
      </div>
    </>
  );
}
export default memo(HighRiskObstetric);

import { memo } from "react"
import { LazyLoadImage } from "react-lazy-load-image-component";

import "../../../../node_modules/bootstrap/dist/css/bootstrap.min.css"
import "../.././inner.css"

function Mobilecontdroller(props){
    function fromchanger(){
        props.setFormcontrol("block")
    }
    return (<>
    <div className="mobilecontrol">
        <div className="row">
            <div className="col-12 mobicon">
                <p>Please contact us with your question and needs, our<br className="fhu"></br> team would be delighted to assist you promptly.</p>
            </div>
            <div className="col-12 buttonmob">
                <button onClick={fromchanger} className="conbutt">Contact Us</button>
            </div>
        </div>
        <div className="sooboxone">
            <div className="sooin">
            <a href="">
                <div className="soonon">
                    <LazyLoadImage src="../photo/whatsapp.png"></LazyLoadImage>
                </div>
            </a>
            </div>
            <div className="soointo">
            <a href="">
                <div className="soonon">
                    <LazyLoadImage src="../photo/telephone.png"></LazyLoadImage>
                </div>
            </a>
            </div>
        </div>
    </div>
    </>)
}
export default memo(Mobilecontdroller);
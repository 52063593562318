import { Link } from "react-router-dom";
import "../../node_modules/bootstrap/dist/css/bootstrap.min.css"
import "./inner.css"
import { memo } from "react";
import Linkmenubox from "./Linkmenubox";
function Headermenu(props){
    function stylecontrol(){
        props.setFirst("block")
    }
    return (<>
    <header className="headsec">
    <div className="deheaerbox"></div>
        <div className="container headcontain">
            <div className="outerhead">
            <div>
                <Link to="/" className="headermenu"><img src="../photo/logo.jpg" alt="" /></Link>
            </div>
            <div className="likeneadder">
                <div className="topheaerbox">
                    <div className="headinfo">
                        <div className="contenticon">
                            <span className="iconheader">
                                <img src="../photo/email (1).png" alt="" />
                            </span>
                            <p>docck2018@gmail.com</p>
                        </div>
                        <div className="contenticon rigtmar">
                            <span className="iconheader">
                                <img src="../photo/telephone-call.png" alt="" />
                            </span>
                            <p>84310 69548</p>
                        </div>
                    </div>
                    <div>
                        <ul className="softicon">
                            <li>
                                <a href="">
                                    <span className="iconheader">
                                        <img src="../photo/facebook-app-symbol.png" alt="" />
                                    </span>
                                </a>
                            </li>
                            <li>
                                <a href="">
                                    <span className="iconheader">
                                        <img src="../photo/instagram.png" alt="" />
                                    </span>
                                </a>
                            </li>
                            <li>
                                <a href="">
                                    <span className="iconheader">
                                        <img src="../photo/google.png" alt="" />
                                    </span>
                                </a>
                            </li>
                            <li>
                                <a href="">
                                    <span className="iconheader">
                                        <img src="../photo/twitter.png" alt="" />
                                    </span>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="menulist">
                <Linkmenubox/>
                    <div className="apponitlink">
                        <a href="tel:84310 69548" className="headappoint">Appointment</a>
                    </div>
                    <div className="list">
                        <img onClick={stylecontrol} src="../photo/list.png" alt="" />
                    </div>
                </div>
            </div>
            </div>
        </div>
    </header>
    </>)
}
export default memo(Headermenu);
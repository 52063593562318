import { memo } from "react"
import { Link } from "react-router-dom"

function AboutUsBanner(){
    return(<>
    <div className="aboutbanner">
        <div>
            <div className="bannertia">
                <h3>About Us</h3>
            </div>
            <div className="bannersubme">
                <Link className="bannerli"><h6>Home</h6></Link>
                <h6 className="bannersla"> / </h6>
                <h6>About Us</h6>
            </div>
        </div>
    </div>
    </>)
}
export default memo(AboutUsBanner)
import { memo, useEffect } from "react";
import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import ".././inner.css";
import { LazyLoadImage } from "react-lazy-load-image-component";

function PreEclampsia() {
  useEffect(() => {
    document.title = "Pre eclampsia treatment in puttenahalli";
    const newMetaDescription =
      "We are the best Gynecology clinic for the best Pre eclampsia treatment in puttenahalli. Don’t hesitate and visit us today.";
    const metaTag = document.querySelector('meta[name="description"]');
    if (metaTag) {
      metaTag.setAttribute("content", newMetaDescription);
    }
  }, []);
  return (
    <>
      <h2 style={{ fontSize: "0px" }}>
        Pre eclampsia treatment in puttenahalli
      </h2>
      <div className="serinfobox">
        <div className="serinfoimg">
          <LazyLoadImage src="../photo/sers.jpg" />
        </div>
        <div className="textareaofser">
          <h4>Pre-Eclampsia</h4>
          <p>
            Pre-eclampsia is a condition that can occur during pregnancy,
            typically after the 20th week or in the postpartum period. It is
            characterized by high blood pressure (hypertension) and signs of
            damage to other organ systems, most commonly the liver and kidneys.
            Pre-eclampsia can be a serious and potentially life-threatening
            condition for both the mother and the unborn baby.
          </p>
          <p>
            The exact cause of pre-eclampsia is not fully understood, but it is
            thought to involve problems with the placenta, which is the organ
            that nourishes the fetus during pregnancy. Risk factors for
            developing pre-eclampsia include a history of high blood pressure,
            obesity, diabetes, multiple pregnancies (twins or more), and a
            family history of pre-eclampsia. We are the best Gynecology clinic
            for the best Pre eclampsia treatment in puttenahalli. Don’t hesitate
            and visit us today.
          </p>
          <h5>The symptoms of pre-eclampsia can vary but may include:</h5>
          <h6>1. High blood pressure:</h6>
          <p>
            A blood pressure reading of 140/90 mm Hg or higher on two separate
            occasions, taken at least four hours apart.
          </p>
          <h6>2. Proteinuria:</h6>
          <p>
            The presence of excess protein in the urine, which can be detected
            through a simple urine test. Proteinuria is often a sign of kidney
            damage.
          </p>
          <h6>3. Swelling:</h6>
          <p>
            Edema, particularly in the hands, feet, and face, is common in
            pregnancy. However, sudden or severe swelling, especially if
            accompanied by other symptoms, may be a sign of pre-eclampsia.
          </p>
          <h6>4. Severe headaches:</h6>
          <p>
            Persistent headaches that don't respond to over-the-counter pain
            relievers can be a warning sign of pre-eclampsia.
          </p>
          <h6>5. Changes in vision:</h6>
          <p>
            Blurred vision, sensitivity to light, or temporary loss of vision
            are symptoms that should be taken seriously.
          </p>
          <p>
            If pre-eclampsia is suspected, a healthcare provider will closely
            monitor blood pressure and urine protein levels. They may also order
            blood tests to assess organ function and perform ultrasound
            examinations to check on the well-being of the baby. The only
            definitive treatment for pre-eclampsia is delivery of the baby.
            Depending on the severity of the condition and how far along the
            pregnancy is, healthcare providers may recommend early delivery or,
            if the fetus is not mature enough, they may attempt to manage the
            condition with medication and close monitoring until the baby can be
            safely delivered.
          </p>
          <p>
            If left untreated, pre-eclampsia can progress to eclampsia, which is
            characterized by seizures and can be life-threatening for both the
            mother and the baby. Therefore, it is important for pregnant women
            to attend regular prenatal check-ups and report any concerning
            symptoms to their healthcare provider.
          </p>
        </div>
      </div>
    </>
  );
}
export default memo(PreEclampsia);

import { memo, useEffect } from "react";
import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import ".././inner.css";
import { LazyLoadImage } from "react-lazy-load-image-component";

function EctopicPregnancy() {
  useEffect(() => {
    document.title = "Best ectopic pregnancy treatment in Puttenahalli";
    const newMetaDescription =
      "We are the best gynecology clinic in Puttenahalli for the best ectopic pregnancy treatment in Puttenahalli. Visit us today.";
    const metaTag = document.querySelector('meta[name="description"]');
    if (metaTag) {
      metaTag.setAttribute("content", newMetaDescription);
    }
  }, []);
  return (
    <>
      <h2 style={{ fontSize: "0px" }}>
        Best ectopic pregnancy treatment in Puttenahalli
      </h2>
      <div className="serinfobox">
        <div className="serinfoimg">
          <LazyLoadImage src="../photo/serrr.jpg" />
        </div>
        <div className="textareaofser">
          <h4>Ectopic Pregnancy</h4>
          <p>
            An ectopic pregnancy, also known as a tubal pregnancy, occurs when a
            fertilized egg implants and grows outside the uterus, usually in one
            of the fallopian tubes. Ectopic pregnancies are not viable and pose
            a serious risk to the mother's health. We are the best gynecology
            clinic in Puttenahalli for the best ectopic pregnancy treatment in
            Puttenahalli. Visit us today.
          </p>
          <h5>1. Implantation outside the uterus: </h5>
          <p>
            In a normal pregnancy, the fertilized egg implants and grows inside
            the uterus. In an ectopic pregnancy, the fertilized egg implants in
            a location outside the uterus, most commonly in one of the fallopian
            tubes. However, it can also occur in other areas such as the cervix,
            ovary, or abdominal cavity.
          </p>
          <h5>2. Diagnosis:</h5>
          <p>
            Ectopic pregnancy is diagnosed through a combination of clinical
            evaluation and diagnostic tests, which may include:
          </p>
          <h6>Transvaginal ultrasound:</h6>
          <p>
            This is the most common imaging tool used to visualize the location
            of the pregnancy and determine if it is ectopic.
          </p>
          <h6>Blood tests:</h6>
          <p>
            Measurement of the hormone human chorionic gonadotropin (hCG) levels
            can help determine the viability and location of the pregnancy.
            Abnormal hCG levels or a slower-than-expected rise may indicate an
            ectopic pregnancy.
          </p>
          <h5>3. Treatment: </h5>
          <p>
            The goal of treatment for ectopic pregnancy is to remove the
            non-viable pregnancy and prevent life-threatening complications.
            Treatment options include:
          </p>
          <h6>Medication:</h6>
          <p>
            Methotrexate, a medication that stops the growth of the developing
            embryo, can be used to dissolve the pregnancy in cases where the
            ectopic pregnancy is detected early and the woman is stable.
          </p>
          <h6>Surgery: </h6>
          <p>
            If the ectopic pregnancy has ruptured or is at high risk of rupture,
            surgical intervention may be necessary. Laparoscopic surgery is
            commonly used to remove the ectopic pregnancy while preserving the
            fallopian tube.
          </p>
          <h5>4. Emotional support:</h5>
          <p>
            Experiencing an ectopic pregnancy can be emotionally distressing for
            women and their partners. It is essential to provide emotional
            support, counseling, and resources to cope with the loss and the
            potential impact on future fertility.
          </p>
          <p>
            Early detection and prompt management of ectopic pregnancy are
            crucial to prevent complications such as rupture and internal
            bleeding. Women who experience symptoms of ectopic pregnancy should
            seek immediate medical attention for evaluation and appropriate
            treatment.
          </p>
        </div>
      </div>
    </>
  );
}
export default memo(EctopicPregnancy);

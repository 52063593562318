import { memo } from "react"
import { Link } from "react-router-dom"
import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css"
import ".././inner.css"

function Gallerybanner(){
    return (<>
        <div className="gallerybanner mb-5">
            <div>
                <div className="bannertia">
                    <h3>Gallery</h3>
                </div>
                <div className="bannersubme">
                    <Link className="bannerli"><h6>Home</h6></Link>
                    <h6 className="bannersla"> / </h6>
                    <h6>Gallery</h6>
                </div>
            </div>
        </div>
    </>)
}
export default memo(Gallerybanner)
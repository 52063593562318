import { memo, useContext, useState } from "react"
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Link } from "react-router-dom"
import { data1 } from "../../App";

function Obgservices(){
    const setServiceseta=useContext(data1)
    const [index, setIndex]=useState(6);
    const menu=[
        {
            img:"../photo/obstetrical.png",
            name:"General Obstetrics",
            text:"General obstetrics is a branch of medicine that focuses on the care and management of women during pregnancy, childbirth, and the postpartum period.",
            to:"/service/generalObstetrics"
        },
        {
            img:"../photo/ultrasound.png",
            name:"High Risk Obstetric Cases",
            text:"High-risk obstetric cases refer to pregnancies that have a higher likelihood of complications or adverse outcomes for either the mother or the baby.",
            to:"/service/highRiskObstetric"
        },
        {
            img:"../photo/baby.png",
            name:"PIH(Pregnancy Induced Hypertension)",
            text:"Pregnancy-induced hypertension (PIH), also known as gestational hypertension, is a condition characterized by high blood pressure that develops during pregnancy.",
            to:"/service/pih"
        },
        {
            img:"../photo/uterus.png",
            name:"APH(Antepartum Haemorrhage)",
            text:"Antepartum hemorrhage (APH) refers to vaginal bleeding that occurs after the 24th week of pregnancy but before the birth of the baby.",
            to:"/service/aph"
        },
        {
            img:"../photo/blood.png",
            name:"PPH(Postpartum Haemorrhage)",
            text:"Postpartum hemorrhage (PPH) is defined as excessive bleeding following childbirth, typically within 24 hours after delivery.",
            to:"/service/pph"
        },
        {
            img:"../photo/sexual-health.png",
            name:"Ectopic Pregnancy",
            text:"Ectopic pregnancy is a potentially life-threatening condition in which a fertilized egg implants and develops outside the uterus, typically in the fallopian tube.",
            to:"/service/ectopicPregnancy"
        },
        {
            img:"../photo/pregnancy.png",
            name:"Pre-Eclampsia",
            text:"Preeclampsia, also known as pregnancy-induced hypertension (PIH), is a multisystem disorder that affects pregnant women.",
            to:"/service/preEclampsia"
        },
        {
            img:"../photo/abortion.png",
            name:"Inevitable Abortion",
            text:"Inevitable abortion, also known as an incomplete abortion, refers to a pregnancy loss that is in progress and cannot be stopped or reversed.",
            to:"/service/inevitableAbortion"
        },
        {
            img:"../photo/respiratory-system.png",
            name:"Respiratory Problems With Pregnancy",
            text:"Pregnancy can sometimes be associated with respiratory problems or changes due to various physiological and hormonal factors.",
            to:"/service/respiratory"
        },
        {
            img:"../photo/broken.png",
            name:"Orthopedic Problem With Pregnancy",
            text:"Pregnancy can sometimes be associated with respiratory problems or changes due to various physiological and hormonal factors.",
            to:"/service/orthopedicProblem"
        },
        {
            img:"../photo/virus.png",
            name:"Infections Disease",
            text:"Infectious diseases are caused by pathogenic microorganisms, such as bacteria, viruses, fungi, or parasites, that can enter the body and disrupt normal bodily functions.",
            to:"/service/infectionsDisease"
        },
        {
            img:"../photo/hysterectomy.png",
            name:"Hysterectomy",
            text:"Hysterectomy is a surgical procedure in which the uterus (womb) is removed. It is one of the most common surgical procedures performed on women. ",
            to:"/service/hysterectomy"
        },
    ]
    return (<>
    <section className="sectionmar obgseec">
        <div className="container">
            <div className="row orthocen">
                <h5>OBG Services</h5>
                <h1>Viksha Health Care</h1>
                <h6>Welcome to our Gynecology Services! We understand the unique<br className="orthobr"></br> healthcare needs of women, and our specialty Clinic is committed to providing<br className="orthobr"></br> comprehensive gynecological care.</h6>
            </div>
            <div className="row">
                {
                    menu.map((elem, number)=>{
                        const {img, name, to, text}=elem
                        if(number<index){
                            return (<>
                                <div className="col-lg-4 col-md-6 othoserbox">
                            <div className="orthocon">
                                <div className="ortobarcon">
                                    <div className="addbar"></div>
                                </div>
                                <div className="innerorthocon">
                                    <div className="obghohe">
                                        <div className="addresicon">
                                            <LazyLoadImage src={img} alt="" />
                                        </div>
                                        <div className="orthosenam obgtext">
                                            <h6>{name}</h6>
                                            <p>{text}</p>
                                        </div>
                                    </div>
                                    <Link onClick={()=>{
                                        setServiceseta(name)
                                      }} to={to} className="orthoread obgcolor mt-2">
                                            <h6>Read More</h6>
                                    </Link>
                                </div>
                                <div className="ortobarcon">
                                    <div className="orthosqu obgcolor"></div>
                                    <div className="orthosqu obgcolor"></div>
                                </div>
                            </div>
                        </div>
                                </>)
                        }
                    })
                }
            </div>
            <div className="row loadcondrol">
                <button className="loadbutt" onClick={()=>{
                    setIndex(menu.length)
                }}><h6>Load Services</h6></button>
            </div>
        </div>
    </section>
    </>)
}
export default memo(Obgservices)
import { memo, useEffect } from "react";
import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import ".././inner.css";
import { LazyLoadImage } from "react-lazy-load-image-component";

function APH() {
  useEffect(() => {
    document.title = "APH treatment in Jayanagar";
    const newMetaDescription =
      "We have the best gynecologist for the best APH treatment in Jayanagar. Feel free to visit us today to know more.";
    const metaTag = document.querySelector('meta[name="description"]');
    if (metaTag) {
      metaTag.setAttribute("content", newMetaDescription);
    }
  }, []);
  return (
    <>
      <h2 style={{ fontSize: "0px" }}>APH treatment in Jayanagar</h2>
      <div className="serinfobox">
        <div className="serinfoimg">
          <LazyLoadImage src="../photo/serp.jpg" />
        </div>
        <div className="textareaofser">
          <h4>APH(Antepartum Haemorrhage)</h4>
          <p>
            Antepartum hemorrhage (APH) refers to bleeding from the vagina
            during pregnancy, occurring after 20 weeks of gestation and before
            the birth of the baby. It is also known as third-trimester bleeding.
            APH can be a significant concern and may indicate a potential
            complication that requires prompt medical attention. We have the
            best gynecologist for the best APH treatment in Jayanagar. Feel free
            to visit us today to know more.
          </p>
          <h5>
            1. Types of APH: There are two main types of antepartum hemorrhage:
          </h5>
          <h6>Placenta previa:</h6>
          <p>
            This occurs when the placenta partially or completely covers the
            cervix. Bleeding occurs when the cervix begins to dilate or efface,
            disrupting the blood vessels within the placenta.
          </p>
          <h6>Placental abruption:</h6>
          <p>
            This refers to the premature separation of the placenta from the
            uterine wall before delivery. It can cause bleeding and compromise
            the oxygen and nutrient supply to the baby.
          </p>
          <h5>2. Risk factors:</h5>
          <p>
            Some factors increase the risk of APH, including advanced maternal
            age, multiparity (having had multiple pregnancies), previous history
            of placenta previa or placental abruption, smoking, high blood
            pressure, and certain conditions like uterine fibroids or a history
            of cervical surgery.
          </p>
          <h5>3. Symptoms: </h5>
          <p>
            The main symptom of APH is vaginal bleeding during the third
            trimester of pregnancy. The bleeding can vary in severity, ranging
            from light spotting to heavy bleeding. Other symptoms may include
            abdominal pain, uterine contractions, and changes in fetal movement.
          </p>
          <h5>4. Diagnosis:</h5>
          <p>
            When a pregnant woman presents with vaginal bleeding, the healthcare
            provider will perform a thorough evaluation. This may involve a
            physical examination, ultrasound scan to assess the location of the
            placenta, and monitoring of fetal heart rate. Blood tests may also
            be done to assess the mother's blood count and coagulation profile.
          </p>
          <h5>5. Complications and outcomes:</h5>
          <p>
            APH can lead to complications for both the mother and the baby.
            These may include preterm birth, fetal distress, intrauterine growth
            restriction, placental insufficiency, and maternal hemorrhage. The
            outcome depends on the underlying cause, the severity of bleeding,
            and the timely management provided.
          </p>
          <p>
            It is essential for pregnant women who experience any vaginal
            bleeding during the third trimester to seek immediate medical
            attention. Early diagnosis and appropriate management of APH can
            help reduce the risk of complications and improve outcomes for both
            the mother and the baby.
          </p>
        </div>
      </div>
    </>
  );
}
export default memo(APH);

import { memo, useContext } from "react"
import "../.././inner.css"
import "../../../../node_modules/bootstrap/dist/css/bootstrap.min.css"
import LazyLoad from "react-lazy-load"
import { LazyLoadImage } from "react-lazy-load-image-component"
import { Link } from "react-router-dom"
import { data1 } from "../../../App"

function Orthopedicser(){
    const setServiceseta=useContext(data1)
    const menu=[
        {
            linkname:"Bone Fracture Treatment Surgical And Conservative Treatment",
            to:"/service/boneFracture"
        },
        {
            linkname:"Sport Medicine",
            to:"/service/sportMedicine"
        },
        {
            linkname:"Arthroscopic Acl Andmenicus Repair Reconstruction",
            to:"/service/arthroscopicAcl"
        },
        {
            linkname:"Arthroscopic Shoulder Surgeries",
            to:"/service/arthroscopicShoulder"
        },
        {
            linkname:"Back Pain And Disc Related Diseases Treatment",
            to:"/service/backPain"
        },
        {
            linkname:"Knee And Hip Replacement Surgery Primary And Revision",
            to:"/service/kneeAndHip"
        },
        {
            linkname:"Diabetic Wound Dressing And Management",
            to:"/service/diabeticWound"
        },
    ]
    const menuone=[
        {
            linkname:"General Obstetrics",
            to:"/service/generalObstetrics"
        },
        {
            linkname:"High Risk Obstetric Cases",
            to:"/service/highRiskObstetric"
        },
        {
            linkname:"PIH(Pregnancy Induced Hypertension)",
            to:"/service/pih"
        },
        {
            linkname:"APH(Antepartum Haemorrhage)",
            to:"/service/aph"
        },
        {
            linkname:"PPH(Postpartum Haemorrhage)",
            to:"/service/pph"
        },
        {
            linkname:"Ectopic Pregnancy",
            to:"/service/ectopicPregnancy"
        },
        {
            linkname:"Pre-Eclampsia",
            to:"/service/preEclampsia"
        },
        {
            linkname:"Inevitable Abortion",
            to:"/service/inevitableAbortion"
        },
        {
            linkname:"Respiratory Problems With Pregnancy",
            to:"/service/respiratory"
        },
        {
            linkname:"Orthopedic Problem With Pregnancy",
            to:"/service/orthopedicProblem"
        },
        {
            linkname:"Infections Disease",
            to:"/service/infectionsDisease"
        },
        {
            linkname:"Hysterectomy",
            to:"/service/hysterectomy"
        },
        
    ]
    const menutwo=[
        {
            linkname:"High intensity digital xray",
            to:"/service/highintensity"
        },
        {
            linkname:"Laboratory blood collection centre",
            to:"/service/laboratory"
        },
        {
            linkname:"Wound dressing",
            to:"/service/wounddressing"
        },
    ]
    
    return (<>
    <div className="orthselink">
        <LazyLoad className="serorthoimg">
            <img src="../photo/joint.png" alt="" />
        </LazyLoad>
        <div className="orthtrianglebox"></div>
        <div>
            {
                menu.map((elem)=>{
                    const {linkname,to}=elem
                    {
                    return (<>
                    <div className="boxininfoma">
                        <Link to={to} onClick={()=>{
                                        setServiceseta(linkname)
                                      }} className="footinfo ortoliname">
                            <div className="arrowicon ortholicontrol">
                                <LazyLoadImage className="arrowbar" src="../photo/arrow-right.png"/>
                            </div>
                            <h6>{linkname}</h6>
                        </Link>
                    </div>
                        </>)
                    }
                })
            }
        </div>
    </div>
    <div className="orthselink">
        <LazyLoad className="serorthoimg">
            <img src="../photo/fallopian-tubes.png" alt="" />
        </LazyLoad>
        <div className="orthtrianglebox"></div>
        <div>
            {
                menuone.map((elem)=>{
                    const {linkname,to}=elem
                    {
                    return (<>
                    <div className="boxininfoma">
                        <Link to={to} onClick={()=>{
                                        setServiceseta(linkname)
                                      }} className="footinfo ortoliname">
                            <div className="arrowicon ortholicontrol">
                                <LazyLoadImage className="arrowbar" src="../photo/arrow-right.png"/>
                            </div>
                            <h6>{linkname}</h6>
                        </Link>
                    </div>
                        </>)
                    }
                })
            }
        </div>
    </div>
    <div className="orthselink">
        <LazyLoad className="serorthoimg">
            <img src="../photo/clinic.png" alt="" />
        </LazyLoad>
        <div className="orthtrianglebox"></div>
        <div>
            {
                menutwo.map((elem)=>{
                    const {linkname,to}=elem
                    {
                    return (<>
                    <div className="boxininfoma">
                        <Link to={to} onClick={()=>{
                                        setServiceseta(linkname)
                                      }} className="footinfo ortoliname">
                            <div className="arrowicon ortholicontrol">
                                <LazyLoadImage className="arrowbar" src="../photo/arrow-right.png"/>
                            </div>
                            <h6>{linkname}</h6>
                        </Link>
                    </div>
                        </>)
                    }
                })
            }
        </div>
    </div>
    </>)
}
export default memo(Orthopedicser)
import { memo, useEffect } from "react";
import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import ".././inner.css";
import { LazyLoadImage } from "react-lazy-load-image-component";

function Respiratory() {
  useEffect(() => {
    document.title = "Best Gynecology clinic in puttenahalli";
    const newMetaDescription =
      "We are the best Gynecology clinic in puttenahalli as we have the best gynecologist doctor in Puttenahalli. Feel free to visit for any issue.";
    const metaTag = document.querySelector('meta[name="description"]');
    if (metaTag) {
      metaTag.setAttribute("content", newMetaDescription);
    }
  }, []);
  return (
    <>
      <h2 style={{ fontSize: "0px" }}>
        Best Gynecology clinic in puttenahalli
      </h2>
      <div className="serinfobox">
        <div className="serinfoimg">
          <LazyLoadImage src="../photo/serl.jpg" />
        </div>
        <div className="textareaofser">
          <h4>Respiratory Problems With Pregnancy</h4>
          <p>
            Respiratory problems during pregnancy can occur due to various
            factors, including changes in hormonal levels, increased demands on
            the respiratory system, and physiological adaptations that take
            place in the body. Some common respiratory issues that women may
            experience during pregnancy include:
          </p>
          <h6>Shortness of breath:</h6>
          <p>
            As pregnancy progresses, the growing uterus can put pressure on the
            diaphragm, limiting its range of motion. This can lead to a feeling
            of breathlessness or shortness of breath, especially in the later
            stages of pregnancy. The increased production of progesterone, a
            hormone that relaxes smooth muscles, can also contribute to a sense
            of breathlessness.
          </p>
          <h6>Nasal congestion and rhinitis:</h6>
          <p>
            Many pregnant women experience nasal congestion and stuffiness,
            often referred to as pregnancy rhinitis. Hormonal changes can cause
            increased blood flow to the nasal passages, leading to swelling of
            the mucous membranes and congestion.
          </p>
          <h6>Increased susceptibility to respiratory infections:</h6>
          <p>
            Pregnant women may be more susceptible to respiratory infections
            such as the common cold or flu due to changes in their immune system
            during pregnancy. These infections can cause symptoms like coughing,
            sneezing, and congestion.
          </p>
          <h6>Asthma:</h6>
          <p>
            Pregnancy can have variable effects on asthma. Some women may
            experience improvement in their asthma symptoms, while others may
            find their symptoms worsen during pregnancy. It is essential for
            pregnant women with asthma to work closely with their healthcare
            provider, and follow any recommended treatment plans to ensure the
            well-being of both the mother and the baby. We are the best
            Gynecology clinic in puttenahalli as we have the best gynecologist
            doctor in Puttenahalli. Feel free to visit for any issue.
          </p>
          <h6>Pre-existing respiratory conditions:</h6>
          <p>
            Pregnant women with pre-existing respiratory conditions such as
            chronic obstructive pulmonary disease (COPD) or asthma need to
            monitor their symptoms closely and work closely with their
            healthcare provider to manage their condition throughout pregnancy.
          </p>
          <p>
            If you experience severe respiratory symptoms during pregnancy, such
            as persistent shortness of breath, chest pain, or significant
            coughing, it is important to consult your healthcare provider. These
            symptoms could indicate a more serious respiratory condition that
            requires medical attention.
          </p>
          <p>
            It's crucial for pregnant women to attend regular prenatal
            check-ups, discuss any respiratory concerns with their healthcare
            provider, and follow any recommended treatment plans to ensure the
            well-being of both the mother and the baby.
          </p>
        </div>
      </div>
    </>
  );
}
export default memo(Respiratory);

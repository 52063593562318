import { memo, useContext } from "react"
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Link } from "react-router-dom";
import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css"
import ".././inner.css"
import { data1 } from "../../App";

function Footer(){
    const setServiceseta=useContext(data1)
    return (<>
    <section className="footblack">
        <div className="container">
            <div className="row">
                <div className="col-lg-3 col-md-6">
                    <div>
                        <div className="footlogobox">
                            <LazyLoadImage className="footlogo" src="../photo/logo.jpg"/>
                        </div>
                        <div className="footadd">
                            <div className="footicon">
                                <LazyLoadImage className="iconfoot" src="../photo/pin.png"/>
                            </div>
                            <h6>95,15th Main, 17th Cross Rd, 5th Phase, J. P. Nagar, Bengaluru, Karnataka 560078</h6>
                        </div>
                        <div className="footadd footmar">
                            <div className="footicon">
                                <LazyLoadImage className="iconfoot" src="../photo/phone.png"/>
                            </div>
                            <h6>84310 69548</h6>
                        </div>
                        <div className="footadd footmar">
                            <div className="footicon">
                                <LazyLoadImage className="iconfoot" src="../photo/search-mail.png"/>
                            </div>
                            <h6>docck2018@gmail.com</h6>
                        </div>
                        <div className="footiconbox">
                            <a href="" className="footicon softiconmar">
                                <LazyLoadImage className="iconfoot" src="../photo/facebook.png"/>
                            </a>
                            <a href="" className="footicon softiconmar">
                                <LazyLoadImage className="iconfoot" src="../photo/instagram (1).png"/>
                            </a>
                            <a href="" className="footicon softiconmar">
                                <LazyLoadImage className="iconfoot" src="../photo/google (1).png"/>
                            </a>
                            <a href="" className="footicon softiconmar">
                                <LazyLoadImage className="iconfoot" src="../photo/twitter (3).png"/>
                            </a>
                        </div>
                    </div>
                </div>

                <div className="col-lg-3 col-md-6">
                    <div>
                        <div className="footertittle">
                            <h5>Orthopedics Services</h5>
                        </div>
                        <Link onClick={()=>{
                                        setServiceseta("Bone Fracture Treatment Surgical And Conservative Treatment")
                                      }} to="/service" className="footinfo">
                            <div className="arrowicon">
                                <LazyLoadImage className="arrowbar" src="../photo/arrow-right.png"/>
                            </div>
                            <h6>Bone Fracture Treatment Surgical And Conservative Treatment</h6>
                        </Link>
                        <Link onClick={()=>{
                                        setServiceseta("Sport Medicine")
                                      }} to="/service/sportMedicine" className="footinfo">
                            <div className="arrowicon">
                                <LazyLoadImage className="arrowbar" src="../photo/arrow-right.png"/>
                            </div>
                            <h6>Sport Medicine</h6>
                        </Link>
                        <Link onClick={()=>{
                                        setServiceseta("Arthroscopic Acl Andmenicus Repair Reconstruction")
                                      }} to="/service/arthroscopicAcl" className="footinfo">
                            <div className="arrowicon">
                                <LazyLoadImage className="arrowbar" src="../photo/arrow-right.png"/>
                            </div>
                            <h6>Arthroscopic Acl Andmenicus Repair Reconstruction</h6>
                        </Link>
                        <Link onClick={()=>{
                                        setServiceseta("Arthroscopic Shoulder Surgeries")
                                      }} to="/service/arthroscopicShoulder" className="footinfo">
                            <div className="arrowicon">
                                <LazyLoadImage className="arrowbar" src="../photo/arrow-right.png"/>
                            </div>
                            <h6>Arthroscopic Shoulder Surgeries</h6>
                        </Link>
                        <Link onClick={()=>{
                                        setServiceseta("Back Pain And Disc Related Diseases Treatment")
                                      }} to="/service/backPain" className="footinfo">
                            <div className="arrowicon">
                                <LazyLoadImage className="arrowbar" src="../photo/arrow-right.png"/>
                            </div>
                            <h6>Back Pain And Disc Related Diseases Treatment</h6>
                        </Link>
                        <Link onClick={()=>{
                                        setServiceseta("Knee And Hip Replacement Surgery Primary And Revision")
                                      }} to="/service/kneeAndHip" className="footinfo">
                            <div className="arrowicon">
                                <LazyLoadImage className="arrowbar" src="../photo/arrow-right.png"/>
                            </div>
                            <h6>Knee And Hip Replacement Surgery Primary And Revision</h6>
                        </Link>
                        <Link onClick={()=>{
                                        setServiceseta("Diabetic Wound Dressing And Management")
                                      }} to="/service/diabeticWound" className="footinfo">
                            <div className="arrowicon">
                                <LazyLoadImage className="arrowbar" src="../photo/arrow-right.png"/>
                            </div>
                            <h6>Diabetic Wound Dressing And Management</h6>
                        </Link>
                    </div>
                </div>

                <div className="col-lg-3 col-md-6">
                    <div>
                        <div className="footertittle">
                            <h5>OBG Services</h5>
                        </div>
                        <Link onClick={()=>{
                                        setServiceseta("General Obstetrics")
                                      }} to="/service/generalObstetrics" className="footinfo">
                            <div className="arrowicon">
                                <LazyLoadImage className="arrowbar" src="../photo/arrow-right.png"/>
                            </div>
                            <h6>General Obstetrics</h6>
                        </Link>
                        <Link onClick={()=>{
                                        setServiceseta("High Risk Obstetric Cases")
                                      }} to="/service/highRiskObstetric" className="footinfo">
                            <div className="arrowicon">
                                <LazyLoadImage className="arrowbar" src="../photo/arrow-right.png"/>
                            </div>
                            <h6>High Risk Obstetric Cases</h6>
                        </Link>
                        <Link onClick={()=>{
                                        setServiceseta("PIH(Pregnancy Induced Hypertension)")
                                      }} to="/service/pih" className="footinfo">
                            <div className="arrowicon">
                                <LazyLoadImage className="arrowbar" src="../photo/arrow-right.png"/>
                            </div>
                            <h6>PIH(Pregnancy Induced Hypertension)</h6>
                        </Link>
                        <Link onClick={()=>{
                                        setServiceseta("APH(Antepartum Haemorrhage)")
                                      }} to="/service/aph" className="footinfo">
                            <div className="arrowicon">
                                <LazyLoadImage className="arrowbar" src="../photo/arrow-right.png"/>
                            </div>
                            <h6>APH(Antepartum Haemorrhage)</h6>
                        </Link>
                        <Link onClick={()=>{
                                        setServiceseta("PPH(Postpartum Haemorrhage)")
                                      }} to="/service/pph" className="footinfo">
                            <div className="arrowicon">
                                <LazyLoadImage className="arrowbar" src="../photo/arrow-right.png"/>
                            </div>
                            <h6>PPH(Postpartum Haemorrhage)</h6>
                        </Link>
                        <Link onClick={()=>{
                                        setServiceseta("Ectopic Pregnancy")
                                      }} to="/service/ectopicPregnancy" className="footinfo">
                            <div className="arrowicon">
                                <LazyLoadImage className="arrowbar" src="../photo/arrow-right.png"/>
                            </div>
                            <h6>Ectopic Pregnancy</h6>
                        </Link>
                        <Link onClick={()=>{
                                        setServiceseta("Pre-Eclampsia")
                                      }} to="/service/preEclampsia" className="footinfo">
                            <div className="arrowicon">
                                <LazyLoadImage className="arrowbar" src="../photo/arrow-right.png"/>
                            </div>
                            <h6>Pre-Eclampsia</h6>
                        </Link>
                        <Link onClick={()=>{
                                        setServiceseta("Inevitable Abortion")
                                      }} to="/service/inevitableAbortion" className="footinfo">
                            <div className="arrowicon">
                                <LazyLoadImage className="arrowbar" src="../photo/arrow-right.png"/>
                            </div>
                            <h6>Inevitable Abortion</h6>
                        </Link>
                    </div>
                </div>

                <div className="col-lg-3 col-md-6">
                    <div>
                        <div className="footertittle">
                            <h5>Services in clinic</h5>
                        </div>
                        <Link onClick={()=>{
                                        setServiceseta("High intensity digital xray")
                                      }} to="/service/highintensity" className="footinfo">
                            <div className="arrowicon">
                                <LazyLoadImage className="arrowbar" src="../photo/arrow-right.png"/>
                            </div>
                            <h6>High intensity digital xray</h6>
                        </Link>
                        <Link onClick={()=>{
                                        setServiceseta("Laboratory blood collection centre")
                                      }} to="/service/laboratory" className="footinfo">
                            <div className="arrowicon">
                                <LazyLoadImage className="arrowbar" src="../photo/arrow-right.png"/>
                            </div>
                            <h6>Laboratory blood collection centre</h6>
                        </Link>
                        <Link onClick={()=>{
                                        setServiceseta("Wound dressing")
                                      }} to="/service/wounddressing" className="footinfo">
                            <div className="arrowicon">
                                <LazyLoadImage className="arrowbar" src="../photo/arrow-right.png"/>
                            </div>
                            <h6>Wound dressing</h6>
                        </Link>
                        <div className="mailsender">
                            <input className="mailinpu"></input>
                            <div className="sendboxem">
                                <LazyLoadImage  className="sendemail" src="../photo/search-mail.png"></LazyLoadImage>
                            </div>
                        </div>
                        <div className="footmapdi">
                        <iframe className="footmap" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3889.0609402557434!2d77.58933237473317!3d12.903802987405383!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae1516bf217723%3A0xa1830d0adcd10419!2s95%2C%2015th%20Main%20Rd%2C%205th%20Phase%2C%20J%20P%20Nagar%20Phase%205%2C%20J.%20P.%20Nagar%2C%20Bengaluru%2C%20Karnataka%20560078!5e0!3m2!1skn!2sin!4v1684741264703!5m2!1skn!2sin" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                        </div>
                    </div>
                </div>


            </div>
            <div className="row rowcop">
                <div className="rowcopin">
                   <div className="copy">
                   <div><LazyLoadImage className="copyri" src="../photo/copyright.png"/></div>
                    <h6> Viksha Health Care, Design By </h6>
                   </div>
                     <div><a className="acopy" href=""><h6> Appaddindia.com</h6></a></div>
                </div>
            </div>
        </div>
    </section>
    </>)
}
export default memo(Footer);
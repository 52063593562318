import { memo, useContext, useEffect, useRef, useState } from "react"
import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css"
import ".././inner.css"
import { Link } from "react-router-dom"
import { LazyLoadImage } from "react-lazy-load-image-component";
import { data1 } from "../../App"

function ClinicServices(){
    const [windowtrue, setWindowtrue]=useState(true);
    const [motioner, setMotioner]=useState("clinicbox");
    const setServiceseta=useContext(data1)
    const myref=useRef();
    function scrollerevent(){
        const innerheight=myref.current.getBoundingClientRect().top;
                const outer=window.innerHeight
                if(innerheight<outer){
                    setWindowtrue(false)
                    setMotioner("clinicbox clientmotion");
                }
    }
    useEffect(()=>{
            window.addEventListener('scroll',scrollerevent);
            return (()=>{
                window.removeEventListener('scroll',scrollerevent);
            })
    })
    
    return (<>
    <section className="clinicseec">
        <div className="container">
            <div className="row cliniccen">
                <h5>Viksha Health Care</h5>
                <h1>Services in clinic</h1>
                <h6>We at Viksha are making the best of the exceptionally<br className="orthobr"></br> talented specialists and the latest equipment to provide you <br className="orthobr"></br>the best of medical facilities to ensure best health for you.</h6>
            </div>
            <div className="row">
                <div ref={myref} className="col-lg-4 col-md-6 clinicpad">
                    <div className={motioner}>
                        <div>
                        <div className="addresicon cliniccen">
                            <LazyLoadImage src="../photo/x-ray.png" alt="" />
                        </div>
                        <div className="cliniccen">
                            <div>
                                <h6>High intensity digital xray</h6>
                                <p>High-intensity digital X-ray refers to the use of digital X-ray technology to capture high-resolution and detailed images of the human body.<br></br><p className="lineincrease">a</p></p>
                            </div>
                         </div>
                         <div className="clinicknow">
                            <Link onClick={()=>{
                                        setServiceseta("High intensity digital xray")
                                      }} to="/service/highintensity" className="cliniccen knowbutt">
                                <h6>Know More</h6>
                            </Link>
                         </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 col-md-6 clinicpad">
                    <div className={motioner}>
                        <div>
                        <div className="addresicon cliniccen">
                            <LazyLoadImage src="../photo/blood-test.png" alt="" />
                        </div>
                        <div className="cliniccen">
                            <div>
                                <h6>Laboratory blood  collection centre</h6>
                                <p>A laboratory blood collection center, is a facility where healthcare professionals collect blood samples from patients for diagnostic testing or medical research purposes.</p>
                            </div>
                         </div>
                         <div className="clinicknow">
                            <Link onClick={()=>{
                                        setServiceseta("Laboratory blood  collection centre")
                                      }} to="/service/laboratory" className="cliniccen knowbutt">
                                <h6>Know More</h6>
                            </Link>
                         </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 col-md-6 clinicpad">
                    <div className={motioner}>
                        <div>
                        <div className="addresicon cliniccen">
                            <LazyLoadImage src="../photo/first.png" alt="" />
                        </div>
                        <div className="cliniccen">
                            <div>
                                <h6>Wound dressing</h6>
                                <p>A wound dressing, is a specialized laboratory that focuses on the development, testing, and evaluation of various wound dressings and related products.</p>
                            </div>
                         </div>
                         <div className="clinicknow">
                            <Link onClick={()=>{
                                        setServiceseta("Wound dressing")
                                      }} to="/service/wounddressing" className="cliniccen knowbutt">
                                <h6>Know More</h6>
                            </Link>
                         </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    </>)
}
export default memo(ClinicServices);
import { memo, useEffect } from "react";
import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import ".././inner.css";
import { LazyLoadImage } from "react-lazy-load-image-component";

function Wounddressing() {
  useEffect(() => {
    document.title = "Wound dressing in Jayanagar";
    const newMetaDescription =
      "We have the best facility for Wound dressing in Jayanagar. If you have any wound then feel free to visit us.";
    const metaTag = document.querySelector('meta[name="description"]');
    if (metaTag) {
      metaTag.setAttribute("content", newMetaDescription);
    }
  }, []);
  return (
    <>
      <h2 style={{ fontSize: "0px" }}>Wound dressing in Jayanagar</h2>
      <div className="serinfobox">
        <div className="serinfoimg">
          <LazyLoadImage src="../photo/serj.jpg" />
        </div>
        <div className="textareaofser">
          <h4>Wound dressing</h4>
          <p>
            Wound dressing refers to the materials and techniques used to cover
            and protect a wound, promote healing, and prevent infection. The
            choice of wound dressing depends on factors such as the type of
            wound, its severity, the presence of infection, and the stage of
            healing. We have the best facility for Wound dressing in Jayanagar.
            If you have any wound then feel free to visit us.
          </p>
          <h6>Gauze dressings:</h6>
          <p>
            Non-adherent gauze: This type of dressing is used for wounds with
            minimal or no drainage. It prevents the dressing from sticking to
            the wound and allows for easy removal. Gauze pads or sponges: These
            are absorbent dressings that can be used for wounds with moderate to
            heavy drainage. They absorb excess fluid from the wound and help
            maintain a moist environment.
          </p>
          <h6>Transparent film dressings:</h6>
          <p>
            These dressings are thin, transparent sheets that adhere to the skin
            and create a barrier over the wound. They are useful for superficial
            wounds, minor abrasions, or as a protective covering over other
            dressings. They allow visualization of the wound without needing to
            remove the dressing.
          </p>
          <h6>Hydrocolloid dressings:</h6>
          <p>
            Hydrocolloid dressings contain gel-forming agents that create a
            moist environment and promote autolytic debridement (natural
            breakdown of dead tissue). They are effective for low to moderately
            draining wounds, such as pressure ulcers or shallow abrasions.
          </p>
          <h6>Hydrogel dressings:</h6>
          <p>
            Hydrogel dressings are composed of water or glycerin-based gels that
            provide moisture to dry wounds and help in wound debridement. They
            are beneficial for dry or necrotic wounds, burns, and donor sites.
          </p>
          <h6>Foam dressings:</h6>
          <p>
            Foam dressings are absorbent and provide cushioning for wounds with
            moderate to heavy drainage. They maintain a moist environment,
            promote granulation tissue formation, and protect the wound from
            external contaminants.
          </p>
          <h6>Alginate dressings:</h6>
          <p>
            Alginate dressings are derived from seaweed and are highly
            absorbent. They are effective for heavily draining or exuding
            wounds, such as ulcers or surgical wounds, and can help promote
            hemostasis.
          </p>
          <h6>Antimicrobial dressings:</h6>
          <p>
            These dressings contain agents, such as silver or iodine, which help
            prevent or manage wound infections. They are used for infected
            wounds or wounds at risk of infection.
          </p>
          <p>
            It's important to note that the choice of wound dressing should be
            based on a healthcare professional's assessment of the individual
            wound and its specific needs. Dressings may need to be changed
            regularly, and the wound should be monitored for signs of infection
            or other complications. Proper wound cleansing, debridement (if
            necessary), and dressing application techniques should also be
            followed to ensure optimal wound healing.
          </p>
        </div>
      </div>
    </>
  );
}
export default memo(Wounddressing);

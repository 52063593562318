import { memo, useState } from "react";
import { Link } from "react-router-dom";
import "../../node_modules/bootstrap/dist/css/bootstrap.min.css"
import "./inner.css"
import Linkmenubox from "./Linkmenubox";


function HeaderTop(props){

    const [position, setPostion]=useState("absolute");
    const [dis, setDis]=useState("none");

    const changer={
        position: `${position}`,
        display:`${dis}`
    }

    window.addEventListener("scroll",()=>{
        if(window.scrollY>200){
            setPostion("fixed")
            setDis("block")
        }else{
            setPostion("absolute")
            setDis("none")
        }
    })
    function stylecontrol(){
        props.setFirst("block")
    }
    return (<>
        <header style={changer} className="headsec headscroll">
        <div className="container headcontain">
            <div className="outerhead">
            <div className="topheadhe">
                <Link className="headtop headtoptwo">
                    <img className="headertopone" src="../photo/logotwo.jpg" alt="" />
                    <img className="headtoptwoa" src="../photo/logothree.jpg" alt="" />
                    </Link>
            </div>
            <div className="likeneadder">
                <div className="menulist">
                <Linkmenubox/>
                    <div className="apponitlink">
                        <Link to="/contact" className="headappoint">Appointment</Link>
                    </div>
                    <div className="lista">
                        <img onClick={stylecontrol} src="../photo/list.png" alt="" />
                    </div>
                </div>
            </div>
            </div>
        </div>
    </header>
    </>)
}
export default memo(HeaderTop);
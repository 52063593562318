import { memo, useEffect, useRef, useState } from "react"
import { LazyLoadImage } from "react-lazy-load-image-component";

function Review(){

    const [number, setNumber]=useState()
    const [index,setIndex]=useState(2)
    const [cycle, setCycle]=useState(true)
    const myref=useRef();
    const menu=[
        {
            name:"Rama Hadimani",
            img:"../photo/man.png",
            star:[
                {
                    id:"../photo/star.png"
                },
                {
                    id:"../photo/star.png"
                },
                {
                    id:"../photo/star.png"
                },
                {
                    id:"../photo/star.png"
                },
                {
                    id:"../photo/star.png"
                },
            ],
            content:"I had fracture and shoulder related issues so went to hospital. Consultation was very nice by the doctor. I can surely recommend doctor to my family or friends circle. I had fallen actually. There was no waiting time when we went. X-ray was suggested and physiotherapy was suggested. Medicines was prescribed by doctor during consultation."
        },
        {
            name:"Kavitha singh",
            img:"../photo/userone.jpg",
            star:[
                {
                    id:"../photo/star.png"
                },
                {
                    id:"../photo/star.png"
                },
                {
                    id:"../photo/star.png"
                },
                {
                    id:"../photo/star.png"
                },
                {
                    id:"../photo/star.png"
                },
            ],
            content:"I recently had the privilege of receiving care at Viksha Name, and I can confidently say that it was an exceptional experience. From start to finish, every aspect of my stay exceeded my expectations, showcasing the hospital's commitment to providing outstanding healthcare."
        },
        {
            name:"Praveen Patil",
            img:"../photo/usersec.jpg",
            star:[
                {
                    id:"../photo/star.png"
                },
                {
                    id:"../photo/star.png"
                },
                {
                    id:"../photo/star.png"
                },
                {
                    id:"../photo/star.png"
                },
                {
                    id:"../photo/star.png"
                },
            ],
            content:"I recently had the opportunity to experience the remarkable care provided by Viksha Hospital Name, and I am delighted to share my positive review. From the moment I entered the hospital, I was impressed by the professionalism and dedication of the staff, the state-of-the-art facilities, and the comprehensive approach to orthopedic treatment."
        },
        {
            name:"Govida Raj",
            img:"../photo/userthree.jpg",
            star:[
                {
                    id:"../photo/star.png"
                },
                {
                    id:"../photo/star.png"
                },
                {
                    id:"../photo/star.png"
                },
                {
                    id:"../photo/star.png"
                },
                {
                    id:"../photo/star.png"
                },
            ],
            content:"The facilities at Viksha Name were impressive. The hospital was clean, well-maintained, and equipped with state-of-the-art technology. The rooms were comfortable and designed with patient comfort in mind. The hospital's commitment to maintaining a safe and hygienic environment was evident in all areas, creating a sense of reassurance and peace of mind."
        },
        {
            name:"Devaraj Kamble",
            img:"../photo/userfour.jpg",
            star:[
                {
                    id:"../photo/star.png"
                },
                {
                    id:"../photo/star.png"
                },
                {
                    id:"../photo/star.png"
                },
                {
                    id:"../photo/star.png"
                },
                {
                    id:"../photo/star.png"
                },
            ],
            content:"I recently visited Viksha Health Care for an X-ray examination, and I am delighted to share my extremely positive experience. From the moment I stepped into the clinic until the completion of the procedure, I was thoroughly impressed by the professionalism, efficiency, and exceptional quality of service provided."
        },
        {
            name:"Shilpa Patil",
            img:"../photo/userfive.jpg",
            star:[
                {
                    id:"../photo/star.png"
                },
                {
                    id:"../photo/star.png"
                },
                {
                    id:"../photo/star.png"
                },
                {
                    id:"../photo/star.png"
                },
                {
                    id:"../photo/star.png"
                },
            ],
            content:"I recently had the privilege of receiving health care services at Viksha Health care, and I am thrilled to share my exceptional experience. From the moment I entered the hospital to the completion of my treatment, I was thoroughly impressed by the quality of care, professionalism of the staff, and the comprehensive range of services provided."
        },
    ]

    function right(){
        setCycle(false)
        if(window.innerWidth>1200){
            setNumber(25)
            if(index<menu.length-1){
                setIndex(index+1);
            }else{
                setIndex(0)
            }
        }else if(window.innerWidth>992){
            setNumber(33.3333)
            if(index<menu.length){
                setIndex(index+1);
            }else{
                setIndex(0)
            }
        }else if(window.innerWidth>768){
            setNumber(50)
            if(index<menu.length-1){
                setIndex(index+1);
            }else{
                setIndex(0)
            }
        }else{
            setNumber(100)
            if(index<menu.length-2){
                setIndex(index+1);
            }else{
                setIndex(0)
            }
        }
    }
    
    function left(){
        setCycle(false)
        if(window.innerWidth>1200){
            setNumber(25)
            if(index>0){
                setIndex(index-1);
            }else{
                setIndex(menu.length-1)
            }
        }else if(window.innerWidth>992){
            setNumber(33.3333)
            if(index>0){
                setIndex(index-1);
            }else{
                setIndex(menu.length)
            }
        }else if(window.innerWidth>768){
            setNumber(50)
            if(index>0){
                setIndex(index-1);
            }else{
                setIndex(menu.length+1)
            }
        }else{
            setNumber(100)
            if(index>0){
                setIndex(index-1);
            }else{
                setIndex(menu.length+2)
            }
        }
    }
    
    function stop(){
        setCycle(false)
    }
    
    const [men,setmen]=useState(menu)
    useEffect(()=>{
        const timer=setInterval(()=>{
            if(cycle==true){
                if(window.innerWidth>1200){
                    setNumber(25)
                    if(index<menu.length-1){
                        setIndex(index+1);
                    }else{
                        setIndex(0)
                    }
                }else if(window.innerWidth>992){
                    setNumber(33.3333)
                    if(index<menu.length){
                        setIndex(index+1);
                    }else{
                        setIndex(0)
                    }
                }else if(window.innerWidth>768){
                    setNumber(50)
                    if(index<menu.length+1){
                        setIndex(index+1);
                    }else{
                        setIndex(0)
                    }
                }else{
                    setNumber(100)
                    if(index<menu.length+2){
                        setIndex(index+1);
                    }else{
                        setIndex(0)
                    }
                }
            }else{
                clearInterval(timer);
            }
        },2000)
        return (()=>{
            clearInterval(timer)
        })
    })
    
        const changer={
            transform:`translateX(-${index*number}%)`
        }
    return (<>
    <section onClick={stop} className="resection">
        <div className="container">
        <div className="row orthocen review">
                <h5>_Our Patients_</h5>
                <h1>What Patients Says?</h1>
                <div>
                    <LazyLoadImage className="google" src="../photo/googlereview.jpg"></LazyLoadImage>
                </div>
            </div>
            <div className="overreview">
                <div ref={myref} style={changer} className="reviewdis">{
                    men.map((elem)=>{
                        const {name, img, star, content}=elem
                        return (<>
                        <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12 col-12 reviewbox">
                             <div>
                                <div className="mainreview">
                                    <div>
                                        <LazyLoadImage className="usser" src={img}></LazyLoadImage>
                                    </div>
                                    <div className="centerre">
                                    <div className="conre">
                                        <h6>{name}</h6>
                                        <ul className="startbox">{
                                            star.map((num)=>{
                                                const {id}=num
                                                return (<>
                                                <li>
                                                    <LazyLoadImage className="star" src={id}></LazyLoadImage>
                                                </li>
                                                </>)
                                            })
                                        }
                                        </ul>
                                    </div>
                                    </div>
                                </div>
                                <p className="reviewp">
                                {content}
                                </p>
                             </div>
                    </div>
                        </>)
                    })
                }{
                    men.map((elem, num)=>{
                        const {name, img, star, content}=elem
                        if(num<3){
                            return (<>
                                <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12 col-12 reviewbox">
                                     <div>
                                        <div className="mainreview">
                                            <div>
                                                <LazyLoadImage className="usser" src={img}></LazyLoadImage>
                                            </div>
                                            <div className="centerre">
                                            <div className="conre">
                                                <h6>{name}</h6>
                                                <ul className="startbox">{
                                                    star.map((num)=>{
                                                        const {id}=num
                                                        return (<>
                                                        <li>
                                                            <LazyLoadImage className="star" src={id}></LazyLoadImage>
                                                        </li>
                                                        </>)
                                                    })
                                                }
                                                </ul>
                                            </div>
                                            </div>
                                        </div>
                                        <p className="reviewp">
                                        {content}
                                        </p>
                                     </div>
                            </div>
                                </>)
                        }
                    })
                }
                </div>
                
            <div className="headre">
                <LazyLoadImage onClick={right} className="arrowr" src="../photo/right-arrow.png"></LazyLoadImage>
            </div>
            <div className="headle">
                <LazyLoadImage onClick={left} className="arrowr" src="../photo/left-arrow.png"></LazyLoadImage>
            </div>
            </div>
        </div>
    </section>
    </>)
}
export default memo(Review);
import { memo, useEffect } from "react";
import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import ".././inner.css";
import { LazyLoadImage } from "react-lazy-load-image-component";

function Hysterectomy() {
  useEffect(() => {
    document.title = "Hysterectomy treatment in Jayanagar";
    const newMetaDescription =
      "We give the best Hysterectomy treatment in Jayanagar by the best doctor in Jayanagar. Visit us today for more information.";
    const metaTag = document.querySelector('meta[name="description"]');
    if (metaTag) {
      metaTag.setAttribute("content", newMetaDescription);
    }
  }, []);
  return (
    <>
      <h2 style={{ fontSize: "0px" }}>Hysterectomy treatment in Jayanagar</h2>
      <div className="serinfobox">
        <div className="serinfoimg">
          <LazyLoadImage src="../photo/sery.jpg" />
        </div>
        <div className="textareaofser">
          <h4>Hysterectomy</h4>
          <p>
            Hysterectomy is a surgical procedure in which the uterus (womb) is
            removed. It is one of the most common surgical procedures performed
            on women. Depending on the specific circumstances and needs of the
            patient, a hysterectomy may involve the removal of other
            reproductive organs, such as the cervix, ovaries, and fallopian
            tubes.
          </p>
          <h5>
            1. There are different reasons why a woman may undergo a
            hysterectomy:
          </h5>
          <h6>Uterine fibroids:</h6>
          <p>
            {" "}
            Fibroids are noncancerous growths that develop in the uterus. They
            can cause symptoms such as heavy menstrual bleeding, pelvic pain,
            and pressure. If fibroids are causing severe symptoms and other
            treatment options have been ineffective, a hysterectomy may be
            recommended.
          </p>
          <h6>Endometriosis:</h6>
          <p>
            Endometriosis is a condition in which the tissue that normally lines
            the uterus grows outside the uterus, leading to pain and other
            symptoms. In severe cases where other treatments have failed to
            provide relief, a hysterectomy may be considered.
          </p>
          <h6>Uterine prolapse:</h6>
          <p>
            Uterine prolapse occurs when the uterus descends or slips into the
            vagina due to weakened pelvic floor muscles and ligaments. If other
            conservative measures are not successful in managing the prolapse, a
            hysterectomy may be performed.
          </p>
          <h6>Gynecologic cancers:</h6>
          <p>
            In cases of uterine, ovarian, cervical, or other gynecologic
            cancers, a hysterectomy may be part of the treatment plan to remove
            the affected organs and prevent the spread of cancer.
          </p>
          <h6>Chronic pelvic pain:</h6>
          <p>
            In some cases, chronic pelvic pain that does not respond to other
            treatments may lead to the consideration of a hysterectomy.
          </p>
          <h5>2. There are different types of hysterectomy:</h5>
          <h6>Total hysterectomy:</h6>
          <p>
            In this procedure, the uterus and cervix are removed. If the ovaries
            and fallopian tubes are also removed, it is called a bilateral
            salpingo-oophorectomy.
          </p>
          <h6>Partial hysterectomy:</h6>
          <p>
            In a partial hysterectomy, only the upper part of the uterus is
            removed, leaving the cervix intact.
          </p>
          <h6>Radical hysterectomy:</h6>
          <p>
            A radical hysterectomy is performed in cases of gynecologic cancer.
            It involves the removal of the uterus, cervix, upper part of the
            vagina, and surrounding tissues, such as lymph nodes.
          </p>
          <p>
            Hysterectomy can be performed through different approaches,
            including abdominal hysterectomy (through an abdominal incision),
            vaginal hysterectomy (through the vagina), laparoscopic hysterectomy
            (using small incisions and a laparoscope), or robot-assisted
            laparoscopic hysterectomy.
          </p>
          <p>
            After a hysterectomy, women will no longer have menstrual periods
            and cannot become pregnant. The procedure can have physical and
            emotional effects, including temporary or permanent menopausal
            symptoms, changes in sexual function, and emotional adjustment. It
            is important for women to discuss their concerns, expectations, and
            potential risks and benefits with their healthcare provider before
            deciding to undergo a hysterectomy. We give the best Hysterectomy
            treatment in Jayanagar by the best doctor in Jayanagar. Visit us
            today for more information.
          </p>
        </div>
      </div>
    </>
  );
}
export default memo(Hysterectomy);

import { memo } from "react";
import BoneFracture from "./BoneFracture";
import SportMedicine from "./SportMedicine";
import ArthroscopicAcl from "./ArthroscopicAcl";
import ArthroscopicShoulder from "./ArthroscopicShoulder";
import BackPain from "./BackPain";
import KneeAndHip from "./KneeAndHip";
import DiabeticWound from "./DiabeticWound";
import Highintensity from "./Highintensity";
import Laboratory from "./Laboratory";
import Wounddressing from "./Wounddressing";
import GeneralObstetrics from "./GeneralObstetrics";
import HighRiskObstetric from "./HighRiskObstetric";
import PIH from "./PIH";
import APH from "./APH";
import PPH from "./PPH";
import EctopicPregnancy from "./EctopicPregnancy";
import PreEclampsia from "./PreEclampsia";
import InevitableAbortion from "./InevitableAbortion";
import Respiratory from "./Respiratory";
import OrthopedicProblem from "./OrthopedicProblem";
import InfectionsDisease from "./InfectionsDisease";
import Hysterectomy from "./Hysterectomy";

const Subjectservice=
[
    {
        name:"Bone Fracture Treatment Surgical And Conservative Treatment",
        bannerimg:"orthopedicbanner",
        bannername:"Bone Fracture Treatment Surgical And Conservative Treatment",
        service:<BoneFracture/>,
    },
    {
        name:"Sport Medicine",
        bannerimg:"orthopedicbanner",
        bannername:"Sport Medicine",
        service:<SportMedicine/>,
    },
    {
        name:"Arthroscopic Acl Andmenicus Repair Reconstruction",
        bannerimg:"orthopedicbanner",
        bannername:"Arthroscopic Acl Andmenicus Repair Reconstruction",
        service:<ArthroscopicAcl/>
    },
    {
        name:"Arthroscopic Shoulder Surgeries",
        bannerimg:"orthopedicbanner",
        bannername:"Arthroscopic Shoulder Surgeries",
        service:<ArthroscopicShoulder/>
    },
    {
        name:"Back Pain And Disc Related Diseases Treatment",
        bannerimg:"orthopedicbanner",
        bannername:"Back Pain And Disc Related Diseases Treatment",
        service:<BackPain/>
    },
    {
        name:"Knee And Hip Replacement Surgery Primary And Revision",
        bannerimg:"orthopedicbanner",
        bannername:"Knee And Hip Replacement Surgery Primary And Revision",
        service:<KneeAndHip/>
    },
    {
        name:"Diabetic Wound Dressing And Management",
        bannerimg:"orthopedicbanner",
        bannername:"Diabetic Wound Dressing And Management",
        service:<DiabeticWound/>
    },
    {
        name:"General Obstetrics",
        bannerimg:"obgsbanner",
        bannername:"General Obstetrics",
        service:<GeneralObstetrics/>,
    },
    {
        name:"High Risk Obstetric Cases",
        bannerimg:"obgsbanner",
        bannername:"High Risk Obstetric Cases",
        service:<HighRiskObstetric/>
    },
    {
        name:"PIH(Pregnancy Induced Hypertension)",
        bannerimg:"obgsbanner",
        bannername:"PIH(Pregnancy Induced Hypertension)",
        service:<PIH/>
    },
    {
        name:"APH(Antepartum Haemorrhage)",
        bannerimg:"obgsbanner",
        bannername:"APH(Antepartum Haemorrhage)",
        service:<APH/>
    },
    {
        name:"PPH(Postpartum Haemorrhage)",
        bannerimg:"obgsbanner",
        bannername:"PPH(Postpartum Haemorrhage)",
        service:<PPH/>
    },
    {
        name:"Ectopic Pregnancy",
        bannerimg:"obgsbanner",
        bannername:"Ectopic Pregnancy",
        service:<EctopicPregnancy/>
    },
    {
        name:"Pre-Eclampsia",
        bannerimg:"obgsbanner",
        bannername:"Pre-Eclampsia",
        service:<PreEclampsia/>
    },
    {
        name:"Inevitable Abortion",
        bannerimg:"obgsbanner",
        bannername:"Inevitable Abortion",
        service:<InevitableAbortion/>
    },
    {
        name:"Respiratory Problems With Pregnancy",
        bannerimg:"obgsbanner",
        bannername:"Respiratory Problems With Pregnancy",
        service:<Respiratory/>
    },
    {
        name:"Orthopedic Problem With Pregnancy",
        bannerimg:"obgsbanner",
        bannername:"Orthopedic Problem With Pregnancy",
        service:<OrthopedicProblem/>
    },
    {
        name:"Infections Disease",
        bannerimg:"obgsbanner",
        bannername:"Infections Disease",
        service:<InfectionsDisease/>
    },
    {
        name:"Hysterectomy",
        bannerimg:"obgsbanner",
        bannername:"Hysterectomy",
        service:<Hysterectomy/>
    },
    {
        name:"High intensity digital xray",
        bannerimg:"clinicbanner",
        bannername:"High intensity digital xray",
        service:<Highintensity/>
    },
    {
        name:"Laboratory blood collection centre",
        bannerimg:"clinicbanner",
        bannername:"Laboratory blood collection centre",
        service:<Laboratory/>
    },
    {
        name:"Wound dressing",
        bannerimg:"clinicbanner",
        bannername:"Wound dressing",
        service:<Wounddressing/>
    },
]
export default Subjectservice;
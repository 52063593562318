import { memo, useEffect } from "react";
import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import ".././inner.css";
import { LazyLoadImage } from "react-lazy-load-image-component";

function GeneralObstetrics() {
  useEffect(() => {
    document.title = "Best general obstetrics in JP Nagar";
    const newMetaDescription =
      "We have the best general obstetrics in JP Nagar who will help you with your pregnancy. Feel free to visit us today";
    const metaTag = document.querySelector('meta[name="description"]');
    if (metaTag) {
      metaTag.setAttribute("content", newMetaDescription);
    }
  }, []);
  return (
    <>
      <h2 style={{ fontSize: "0px" }}>Best general obstetrics in JP Nagar</h2>
      <div className="serinfobox">
        <div className="serinfoimg">
          <LazyLoadImage src="../photo/serk.jpg" />
        </div>
        <div className="textareaofser">
          <h4>General Obstetrics</h4>
          <p>
            General obstetrics is a medical specialty that focuses on the care
            and management of women during pregnancy, childbirth, and the
            postpartum period. Obstetricians, or OB/GYNs
            (obstetrician-gynecologists), specialize in providing comprehensive
            healthcare to women during all stages of pregnancy and beyond. Here
            are some key aspects of general obstetrics, but before that just
            remember we have the best general obstetrics in JP Nagar. Don’t
            hesitate to visit us.
          </p>
          <h6>Prenatal care:</h6>
          <p>
            Obstetricians provide regular prenatal check-ups and monitoring
            throughout pregnancy. They monitor the mother's health and the
            development and well-being of the fetus. Prenatal visits may include
            physical examinations, ultrasound scans, blood tests, and
            discussions about diet, exercise, and prenatal vitamins.
          </p>
          <h6>High-risk pregnancy management:</h6>
          <p>
            Obstetricians manage pregnancies that are considered high risk due
            to factors such as advanced maternal age, multiple pregnancies
            (e.g., twins or triplets), pre-existing medical conditions (like
            diabetes or hypertension), or complications that arise during
            pregnancy. They closely monitor these pregnancies and may recommend
            additional tests or interventions to ensure the health and safety of
            both the mother and the baby.
          </p>
          <h6>Labor and delivery:</h6>
          <p>
            Obstetricians are trained to manage the process of labor and
            childbirth. They provide guidance and support to women during labor,
            monitor the progress of labor, and make decisions regarding pain
            management options and interventions when necessary. They are
            skilled in performing vaginal deliveries and may also perform
            cesarean sections (C-sections) when vaginal delivery is not possible
            or recommended.
          </p>
          <h6>Postpartum care:</h6>
          <p>
            After childbirth, obstetricians provide postpartum care to women,
            ensuring proper healing and recovery. They monitor the physical and
            emotional well-being of the mother, address any concerns or
            complications that may arise, and provide guidance on breastfeeding,
            contraception, and postpartum lifestyle adjustments.
          </p>
          <h6>Fetal monitoring:</h6>
          <p>
            Obstetricians utilize various techniques to monitor the well-being
            of the fetus during pregnancy and labor. This may include
            non-invasive methods like ultrasound scans, Doppler monitoring of
            fetal heart rate, or more advanced methods like fetal monitoring
            during labor.
          </p>
          <h6>Collaborative care:</h6>
          <p>
            Obstetricians often work in collaboration with other healthcare
            professionals, such as midwives, nurses, and perinatologists
            (specialists in high-risk pregnancies). They may refer patients to
            specialized care if needed, such as genetic counseling,
            maternal-fetal medicine specialists, or neonatal specialists.
          </p>
          <h6>Patient education and counseling:</h6>
          <p>
            Obstetricians play a vital role in educating expectant mothers about
            pregnancy, childbirth, and postpartum care. They provide information
            about healthy lifestyle choices, prenatal nutrition, childbirth
            preparation, breastfeeding, and newborn care. They also address any
            concerns or questions that patients may have throughout the
            pregnancy journey.
          </p>
          <p>
            General obstetrics encompasses a wide range of services and care for
            women during pregnancy, childbirth, and the postpartum period.
            Obstetricians play a crucial role in ensuring the health and
            well-being of both the mother and the baby, and they work closely
            with their patients to provide personalized and comprehensive care
            throughout the entire pregnancy experience.
          </p>
        </div>
      </div>
    </>
  );
}
export default memo(GeneralObstetrics);

import { memo } from "react"
import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css"
import ".././inner.css"
import { LazyLoadImage } from 'react-lazy-load-image-component';
import LazyLoad from 'react-lazy-load';
function Obgpedicinfot(props){
    return (<>
    <section className="p-40">
        <div className="container">
        <div className="row">
        <div className="col-lg-6 cenimhdoc topobg">
            <LazyLoadImage className="orthoimgdoc" src="../photo/seconddoc.jpg">
            </LazyLoadImage>
        </div>
        <div className="col-lg-6 textorthobg">
            <div className="obgdoccolrt">
            <h1>Dr Krupanka BV</h1>
            <h3>MBBS, DGO</h3>
            <p>Dr Krupanka BV is consultant in obstetrics and gynecology with 7 year experience she was a meritorious student completed mbbs in ssmc davangere and DGO in jjmc davangere . she has worked under dr viswanath bk renowned dermatologist in davangere  who  specializes of allergies and cosmetology .after her post-graduation she has worked in rural hospital surrounding davangere  were conducted camps for educating women regarding women health and nutrition importance in preganacy and lactation. she has worked in cloud nine hospital Bangalore for 3 year .  Some of the services provided by the doctor are: IUD placement, Maternal Fetal Medicine, infertility treatment and contraception , Nutrition for Pregnant Women and Pre and Post Delivery Care etc.</p>
            <div className="imgcont addwhy orthocol">
                        <div className="imgconbox">
                            <LazyLoadImage src="../photo/pin.png" alt="" />
                        </div>
                        <div className="context">
                            <h6>95,15th Main, 17th Cross Rd, 5th Phase, J. P. Nagar, Bengaluru, Karnataka 560078</h6>
                        </div>
                    </div>
            </div>
        </div>
        <div className="col-lg-6 cenimhdoc bottomobg">
            <LazyLoadImage className="orthoimgdoc" src="../photo/seconddoc.jpg">
            </LazyLoadImage>
        </div>
    </div>
        </div>
    </section>
    </>)
}
export default memo(Obgpedicinfot);
import { memo } from "react"
import { Link } from "react-router-dom"

function ContactUsbanner(){
    return (<>
        <div className="contactbanner mb-5">
            <div>
                <div className="bannertia">
                    <h3>Contact Us</h3>
                </div>
                <div className="bannersubme">
                    <Link className="bannerli"><h6>Home</h6></Link>
                    <h6 className="bannersla"> / </h6>
                    <h6>Contact Us</h6>
                </div>
            </div>
        </div>
    </>)
}
export default memo(ContactUsbanner)
import { memo, useEffect, useState } from "react"
import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css"
import ".././inner.css"
import Orthopedicservice from "./Servicelink/Orthopedicservice"
import BoneFracture from "./BoneFracture"
import SportMedicine from "./SportMedicine"
import { Outlet } from "react-router-dom"

function Servicebox(props){
    return (<>
    <section>
        <div className="container">
            <div className="row">
                <div className="col-lg-4 firstlink">
                    <Orthopedicservice/>
                </div>
                <div className="col-lg-8">
                    <Outlet/>
                </div>
                <div className="col-lg-4 secondlink">
                    <Orthopedicservice/>
                </div>
            </div>
        </div>
    </section>
    </>)
}
export default memo(Servicebox)
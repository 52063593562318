import { memo } from "react"
import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css"
import ".././inner.css"

function Whychooseus(){
    return (<>
    <section>
        <div className="container">
            <div className="row">
                <div className="col-lg-6 whyimg">
                    <img src="../photo/whychoose.jpg" alt="" />
                </div>
                <div className="col-lg-6 whychoose">
                    <div>
                    <h1>Why Choose Us</h1>
                    <p>Welcome to our Orthopedic Services! We are dedicated to providing exceptional care for all your orthopedic needs. Our hospital specializes in the diagnosis, treatment, and rehabilitation of musculoskeletal conditions, helping you regain mobility and improve your quality of life.</p>
                    <p>Welcome to our Gynecology Services! We understand the unique healthcare needs of women, and our hospital is committed to providing comprehensive gynecological care.</p>
                    <div className="imgcont addwhy">
                        <div className="imgconbox">
                            <img src="../photo/google-maps.png" alt="" />
                        </div>
                        <div className="context">
                            <h6>95,15th Main, 17th Cross Rd, 5th Phase, J. P. Nagar, Bengaluru, Karnataka 560078</h6>
                        </div>
                    </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    </>)
}
export default memo(Whychooseus)
import { memo, useEffect } from "react";
import ContactButton from "../Mainbox/ContactButton/ContactButton";
import Mobcontroller from "../Mainbox/ContactButton/Mobcontroller";
import Contactbox from "../Mainbox/Contactbox";
import Footer from "../Mainbox/Footer";
import Review from "../Mainbox/Review";
import Mobmenu from "../Mobmenu";
import Scrolltop from "../Scrolltop";
import Obgdoctort from "./Obgdoctort";
import OrthopedicDoctor from "./OrthopedicDoctor";
import Ourdoctorbanner from "./Ourdoctorbanner";

function Twodoctor(){
    useEffect(()=>{
        window.scrollTo({top:"0px"})
    },[])
    return (<>
        <Scrolltop/>
        <Mobcontroller/>
        <ContactButton/>
        <Mobmenu/>
        <Ourdoctorbanner/>
        <div className="">
            <Obgdoctort/>
        </div>
        <Contactbox/>
        <Review/>
        <Footer/>
        </>)
}
export default memo(Twodoctor)
import { memo } from "react"
import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css"
import ".././inner.css"
import { LazyLoadImage } from "react-lazy-load-image-component"
function Addressinfo(){
    return (<>
    <section style={{zIndex:"1"}} className="adddbox ">
        <div className="container">
            <div className="row">
                <div className="col-lg-4 col-md-6 addinfobox addinfohover">
                    <div className="innerinfobox">
                        <div className="addbar addhover"></div>
                        <div className="textboxin">
                        <div className="addresicon">
                            <LazyLoadImage src="../photo/joint.png" alt="" />
                        </div>
                        <div className="timeinfo">
                            <h5>Orthopedic Consultation</h5>
                            <div className="timebox">
                                <h6>Doctor</h6>
                                <h6>Dr CHETHAN KUMAR </h6>
                            </div>
                            <div className="timebox">
                                <p>Monday-Saturday</p>
                                <p>8:30am-11:00am</p>
                            </div>
                            <div className="timebox">
                                <p>Monday-Saturday</p>
                                <p>5:30pm-9:00pm</p>
                            </div>
                        </div>
                        </div>
                        <div className="trangle">

                        </div>
                    </div>
                </div>
                <div className="col-lg-4 col-md-6 addinfobox addinfohover">
                    <div className="innerinfobox">
                        <div className="addbar addhover"></div>
                        <div className="textboxin">
                        <div className="addresicon">
                            <LazyLoadImage src="../photo/fallopian-tubes.png" alt="" />
                        </div>
                        <div className="timeinfo">
                        <h5>OBG & Gyne Consultaion</h5>
                            <div className="timebox">
                                <h6>Doctor</h6>
                                <h6>Dr KRUPANADA BV   </h6>
                            </div>
                            <div className="timebox">
                                <p>Monday-Saturday</p>
                                <p>12:30pm-2:00am</p>
                            </div>
                            <div className="timebox">
                                <p>Monday-Saturday</p>
                                <p>6:00pm-9:00pm</p>
                            </div>
                        </div>
                        </div>
                        <div className="trangle">

                        </div>
                    </div>
                </div>
                <div className="col-lg-4 col-md-6 addinfobox addinfohover">
                    <div className="innerinfobox">
                        <div className="addbar addhover"></div>
                        <div className="textboxin">
                        <div className="addresicon">
                            <LazyLoadImage src="../photo/clinic.png" alt="" />
                        </div>
                        <div className="timeinfo">
                            <h5>Clinic timing</h5>
                            <div className="timebox">
                                <h6>Lab and x-ray</h6>
                                <h6></h6>
                            </div>
                            <div className="timebox">
                                <p>Monday-Saturday</p>
                                <p>8:00am-9:00pm</p>
                            </div>
                            <div className="infoapp">
                                <h6>Appointment</h6>
                            </div>
                        </div>
                        </div>
                        <div className="trangle">

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    </>)
}
export default memo(Addressinfo)
import { memo, useEffect } from "react";
import ContactButton from "../Mainbox/ContactButton/ContactButton";
import Mobcontroller from "../Mainbox/ContactButton/Mobcontroller";
import Footer from "../Mainbox/Footer";
import Mobmenu from "../Mobmenu";
import Scrolltop from "../Scrolltop";
import Contactmaincon from "./Contactmaincon";
import Whychooseus from "../Mainbox/Whychooseus";
import Contactusbanner from "./Contactusbanner";

function ContactUs(){
    useEffect(()=>{
        window.scrollTo({top:"0px"})
    },[])
    return (<>
    <Scrolltop/>
    <Mobcontroller/>
    <ContactButton/>
    <Mobmenu/>
    <Contactusbanner/>
    <Whychooseus/>
    <Contactmaincon/>
    <Footer/>
    </>)
}
export default memo(ContactUs);
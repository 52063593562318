import { memo, useEffect } from "react";
import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import ".././inner.css";
import { LazyLoadImage } from "react-lazy-load-image-component";

function KneeAndHip() {
  useEffect(() => {
    document.title = "nee & hip replacement surgery in JP Nagar";
    const newMetaDescription =
      "We have the best orthopedic doctor in JP Nagar for the best Knee & hip replacement surgery in JP Nagar. Visit us today.";
    const metaTag = document.querySelector('meta[name="description"]');
    if (metaTag) {
      metaTag.setAttribute("content", newMetaDescription);
    }
  }, []);
  return (
    <>
      <h2 style={{ fontSize: "0px" }}>
        Knee & hip replacement surgery in JP Nagar
      </h2>
      <div className="serinfobox">
        <div className="serinfoimg">
          <LazyLoadImage src="../photo/serf.jpg" />
        </div>
        <div className="textareaofser">
          <h4>Knee And Hip Replacement Surgery Primary And Revision</h4>
          <p>
            Knee and hip replacement surgeries are common procedures performed
            to alleviate pain and restore function in individuals with severe
            joint damage or conditions such as osteoarthritis. These surgeries
            involve replacing the damaged joint surfaces with artificial
            implants made of metal, ceramic, or plastic materials. We have the
            best orthopedic doctor in JP Nagar and we give the best Knee & hip
            replacement surgery in JP Nagar. Visit us today.
          </p>
          <h5>1. Primary Knee and Hip Replacement Surgery: </h5>
          <p>
            Primary knee or hip replacement surgery is the initial procedure
            performed to replace the damaged joint with an artificial implant.
            Here's a general overview of the primary joint replacement process:
          </p>
          <h6>a. Preoperative evaluation: </h6>
          <p>
            Before the surgery, your orthopedic surgeon will evaluate your
            condition through physical examination, imaging tests (such as
            X-rays or MRI), and medical history review to determine the best
            treatment plan.
          </p>
          <h6>b. Anesthesia:</h6>
          <p>
            You will be given anesthesia, either general anesthesia or regional
            anesthesia (such as spinal or epidural anesthesia), to ensure you
            are comfortable and pain-free during the surgery.
          </p>
          <h6>c. Incision:</h6>
          <p>
            The surgeon will make an incision over the affected knee or hip
            joint to access the joint.
          </p>
          <h6>d. Joint preparation:</h6>
          <p>
            The damaged joint surfaces are removed, and the bone is prepared to
            accommodate the artificial implants. In knee replacement, the ends
            of the femur (thigh bone) and tibia (shin bone) are reshaped, and a
            metal femoral component and a plastic or metal tibial component with
            a plastic spacer in between are implanted. In hip replacement, the
            damaged femoral head is removed, and a metal stem with a ball-shaped
            head is inserted into the femur, along with a socket component
            implanted in the hip socket.
          </p>
          <h5>2. Revision Knee and Hip Replacement Surgery:</h5>
          <p>
            Revision knee or hip replacement surgery is performed when a
            previously implanted joint replacement becomes worn out, damaged, or
            fails to function properly. The procedure is more complex than
            primary surgery and involves removing the existing implants and
            replacing them with new ones. The steps involved in revision surgery
            are similar to primary surgery but may require additional procedures
            to address bone loss, infection, or other complications related to
            the previous implant.
          </p>
          <p>
            Revision surgery often requires more extensive planning, specialized
            implants, and may involve longer recovery and rehabilitation periods
            compared to primary surgery. It's important to note that the
            specific details of knee and hip replacement surgeries can vary
            based on individual cases, the surgeon's expertise, and the type of
            implants used. Your orthopedic surgeon will provide detailed
            information, evaluate your specific condition, and recommend the
            most appropriate surgical approach for your knee or hip joint.
          </p>
        </div>
      </div>
    </>
  );
}
export default memo(KneeAndHip);

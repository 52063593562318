import { memo, useContext } from "react"
import { Link } from "react-router-dom";
import "../../node_modules/bootstrap/dist/css/bootstrap.min.css"
import "./inner.css"
import { data,data1 } from "../App";

function LinkMenuBox(){
    const serviceseta=useContext(data);
    const setServiceseta=useContext(data1)
    return (<>
    <ul className="headmenu">
                        <li><Link to="/" className="headlink">Home</Link></li>
                        <li><Link to="/about" className="headlink">About</Link></li>
                        <li><Link className="headlink">Our Doctor</Link>
                        <ul className="submenu">
                            <li>
                            <Link to="/drchethankumarr" className="headlink subheadlink">Dr Chethan Kumar R</Link>
                            </li>
                            <li>
                            <Link to="/drkrupankabv" className="headlink subheadlink">Dr Krupanka BV</Link>
                            </li>
                        </ul>
                        </li>
                        <li><Link className="headlink">Services</Link>
                        <ul className="submenu">
                            <li>
                            <Link className="headlink subheadlink">Orthopedic Services</Link>
                                <div className="obgmenu">
                                    <ul className="ulobgmenu">
                                      <li><Link onClick={()=>{
                                        setServiceseta("Bone Fracture Treatment Surgical And Conservative Treatment")
                                      }} to="/service/boneFracture" className="headlink orsubmenu">Bone Fracture Treatment Surgical And Conservative Treatment</Link></li>
                                      <li><Link onClick={()=>{
                                        setServiceseta("Sport Medicine")
                                      }} to="/service/sportMedicine" className="headlink orsubmenu">Sport Medicine</Link></li>
                                      <li><Link onClick={()=>{
                                        setServiceseta("Arthroscopic Acl Andmenicus Repair Reconstruction")
                                      }} to="/service/arthroscopicAcl" className="headlink orsubmenu">Arthroscopic Acl Andmenicus Repair Reconstruction</Link></li>
                                    </ul>
                                    <ul className="ulobgmenu">
                                       <li><Link onClick={()=>{
                                        setServiceseta("Arthroscopic Shoulder Surgeries")
                                      }} to="/service/arthroscopicShoulder" className="headlink orsubmenu">Arthroscopic Shoulder Surgeries</Link></li>
                                       <li><Link onClick={()=>{
                                        setServiceseta("Back Pain And Disc Related Diseases Treatment")
                                      }} to="/service/backPain" className="headlink orsubmenu">Back Pain And Disc Related Diseases Treatment</Link></li>
                                       <li><Link onClick={()=>{
                                        setServiceseta("Knee And Hip Replacement Surgery Primary And Revision")
                                      }} to="/service/kneeAndHip" className="headlink orsubmenu">Knee And Hip Replacement Surgery Primary And Revision</Link></li>
                                      <li><Link onClick={()=>{
                                        setServiceseta("Diabetic Wound Dressing And Management")
                                      }} to="/service/diabeticWound" className="headlink orsubmenu">Diabetic Wound Dressing And Management</Link></li>
                                    </ul>
                                </div>
                            </li>
                            <li></li>
                            <li>
                            <Link className="headlink subheadlink">OBG Services</Link>
                                <div className="obgmenu">
                                    <ul className="ulobgmenu">
                                      <li><Link onClick={()=>{
                                        setServiceseta("General Obstetrics")
                                      }} to="/service/generalObstetrics" className="headlink obgsubmenu">General Obstetrics</Link></li>
                                      <li><Link onClick={()=>{
                                        setServiceseta("High Risk Obstetric Cases")
                                      }} to="/service/highRiskObstetric" className="headlink obgsubmenu">High Risk Obstetric Cases</Link></li>
                                      <li><Link onClick={()=>{
                                        setServiceseta("PIH(Pregnancy Induced Hypertension)")
                                      }} to="/service/pih" className="headlink obgsubmenu">PIH(Pregnancy Induced Hypertension)</Link></li>
                                      <li><Link onClick={()=>{
                                        setServiceseta("APH(Antepartum Haemorrhage)")
                                      }} to="/service/aph" className="headlink obgsubmenu">APH(Antepartum Haemorrhage)</Link></li>
                                      <li><Link onClick={()=>{
                                        setServiceseta("PPH(Postpartum Haemorrhage)")
                                      }} to="/service/pph" className="headlink obgsubmenu">PPH(Postpartum Haemorrhage)</Link></li>
                                      <li><Link onClick={()=>{
                                        setServiceseta("Ectopic Pregnancy")
                                      }} to="/service/ectopicPregnancy" className="headlink obgsubmenu">Ectopic Pregnancy</Link></li>
                                    </ul>
                                    <ul className="ulobgmenu">
                                       <li><Link onClick={()=>{
                                        setServiceseta("Pre-Eclampsia")
                                      }} to="/service/preEclampsia" className="headlink obgsubmenu">Pre-Eclampsia</Link></li>
                                       <li><Link onClick={()=>{
                                        setServiceseta("Inevitable Abortion")
                                      }} to="/service/inevitableAbortion" className="headlink obgsubmenu">Inevitable Abortion</Link></li>
                                       <li><Link onClick={()=>{
                                        setServiceseta("Respiratory Problems With Pregnancy")
                                      }} to="/service/respiratory" className="headlink obgsubmenu">Respiratory Problems With Pregnancy</Link></li>
                                       <li><Link onClick={()=>{
                                        setServiceseta("Orthopedic Problem With Pregnancy")
                                      }} to="/service/orthopedicProblem" className="headlink obgsubmenu">Orthopedic Problem With Pregnancy</Link></li>
                                       
                                       <li><Link onClick={()=>{
                                        setServiceseta("Infections Disease")
                                      }} to="/service/infectionsDisease" className="headlink obgsubmenu">Infections Disease</Link></li>
                                       <li><Link onClick={()=>{
                                        setServiceseta("Hysterectomy")
                                      }} to="/service/hysterectomy" className="headlink obgsubmenu">Hysterectomy</Link></li>
                                    </ul>
                                </div>
                            </li>
                            <li>
                            <Link className="headlink subheadlink">Services in clinic</Link>
                                <div className="clinicmenu">
                                    <ul className="ulobgmenu">
                                      <li><Link onClick={()=>{
                                        setServiceseta("High intensity digital xray")
                                      }} to="/service/highintensity" className="headlink clinicsubmenu">High intensity digital xray</Link></li>
                                      <li><Link onClick={()=>{
                                        setServiceseta("Laboratory blood collection centre")
                                      }} to="/service/laboratory" className="headlink clinicsubmenu">Laboratory blood collection centre</Link></li>
                                      <li><Link onClick={()=>{
                                        setServiceseta("Wound dressing")
                                      }} to="/service/wounddressing" className="headlink clinicsubmenu">Wound dressing</Link></li>
                                    </ul>
                                </div>
                            </li>
                        </ul>
                        </li>
                        <li><Link to="/gallery" className="headlink">Gallery</Link></li>
                        <li><Link to="/contact" className="headlink">Contact</Link></li>
                    </ul>
    </>)
}
export default memo(LinkMenuBox);
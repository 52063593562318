import { memo, useEffect } from "react";
import ContactButton from "../Mainbox/ContactButton/ContactButton";
import Mobcontroller from "../Mainbox/ContactButton/Mobcontroller";
import Footer from "../Mainbox/Footer";
import Mobmenu from "../Mobmenu";
import Scrolltop from "../Scrolltop";
import Contactbox from "../Mainbox/Contactbox";
import Gallerybanner from "./Gallerybanner";
import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css"
import ".././inner.css"
import Gallerybox from "./Gallerybox";
import Galleryvedi from "./Galleryvedi";

function Gallery(){
    useEffect(()=>{
        window.scrollTo({top:"0px"})
    },[])
    return (<>
    <Scrolltop/>
    <Mobcontroller/>
    <ContactButton/>
    <Mobmenu/>
    <Gallerybanner/>
    <Galleryvedi/>
    <Gallerybox/>
    <Contactbox/>
    <Footer/>
    </>)
}
export default memo(Gallery);
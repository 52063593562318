import { memo, useState } from "react"
import { Link } from "react-router-dom"
import "../../node_modules/bootstrap/dist/css/bootstrap.min.css"
import "./inner.css"
import Mobfirstlist from "./Mobfirstlist"
import Mobsecondlist from "./Mobsecondlist"
import Mobthreelist from "./Mobthreelist"
import Mobfourlist from "./Mobfourlist"

function Mobilemenu(props){
    const [top, setTop]=useState("block")
    const [second, setSecond]=useState("none")
    const [three, setThree]=useState('none')
    const [four, setFour]=useState("none")
    window.addEventListener("resize",()=>{
        props.setFirst("none")
        setTop("block")
        setSecond("none")
        setThree("none")
        setFour("none")
    })
    const firstbox={
        display:`${props.first}`
    }
    return (<>
    <div style={firstbox} className="firstmenu">
    <div className="bafirstmenu">
    <header className="headsec">
    <div className="deheaerbox"></div>
        <div className="container headcontain">
            <div className="outerhead menucenlo">
            <div>
                <Link className="headermenu"><img src="../photo/logo.jpg" alt="" /></Link>
            </div>
            </div>
        </div>
    </header>
    <Mobfirstlist top={top} setFirst={props.setFirst} setTop={setTop} setSecond={setSecond} setFour={setFour} setThree={setThree}/>
    <Mobsecondlist second={second} setSecond={setSecond} setFour={setFour} setThree={setThree} setTop={setTop} setFirst={props.setFirst}/>
    <Mobthreelist three={three} setThree={setThree} setSecond={setSecond} setFour={setFour} setTop={setTop} setFirst={props.setFirst}/>
    <Mobfourlist four={four} setFour={setFour} setSecond={setSecond} setThree={setThree} setTop={setTop} setFirst={props.setFirst}/>
    </div>
    </div>
    </>)
}
export default memo(Mobilemenu)
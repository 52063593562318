import { memo, useState } from "react"
import "../../../../node_modules/bootstrap/dist/css/bootstrap.min.css"
import "../.././inner.css"
import { LazyLoadImage } from "react-lazy-load-image-component"

function Controller(props){
    function fromchanger(){
        props.setFormcontrol("block")
    }
    return (<>
    <div className="conboxmain">
        <a href="https://wa.me/91431069548?text=call us"><div className="controlthree">
            <LazyLoadImage src="../photo/whatsapp.png"></LazyLoadImage>
        </div></a>
        <div onClick={fromchanger} className="controltwo">
            <LazyLoadImage src="../photo/message.png"></LazyLoadImage>
        </div>
        <div className="controlone">
            <LazyLoadImage src="../photo/contact.png"></LazyLoadImage>
        </div>
    </div>
    </>)
}
export default memo(Controller)
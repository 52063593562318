import { memo } from "react"
import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css"
import ".././inner.css"
import { LazyLoadImage } from "react-lazy-load-image-component"

function Gallerybox(){
    const menu=[
        {
            gal:"../photo/gala.jpg"
        },
        {
            gal:"../photo/galb.jpg"
        },
        {
            gal:"../photo/galc.jpg"
        },
        {
            gal:"../photo/gald.jpg"
        },
        {
            gal:"../photo/gale.jpg"
        },
        {
            gal:"../photo/galf.jpg"
        },
        {
            gal:"../photo/galg.jpg"
        },
        {
            gal:"../photo/galh.jpg"
        },
        {
            gal:"../photo/gali.jpg"
        },
        {
            gal:"../photo/galj.jpg"
        },
        {
            gal:"../photo/galk.jpg"
        },
        {
            gal:"../photo/gall.jpg"
        },
        
    ]
    return (<>
    <section>
        <div className="container">
        <div className="row orthocen mt-2">
                <h1 style={{color:"black"}}>Image Gallery</h1>
            </div>
            <div className="row">
                {
                    menu.map((elem)=>{
                        const {gal}=elem
                        return (<>
                        <div className="col-xl-3 col-lg-4 col-md-6 col-12 mt-4">
                           <LazyLoadImage style={{width:"100%"}} src={gal}/>
                        </div>
                        </>)
                    })
                }
            </div>
        </div>
    </section>
    </>)
}
export default memo(Gallerybox)
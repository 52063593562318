import { memo } from "react"
import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css"
import ".././inner.css"
import { LazyLoadImage } from "react-lazy-load-image-component"

function PIH(){
    return (<>
        <div className="serinfobox">
            <div className="serinfoimg">
                <LazyLoadImage src="../photo/serq.jpg"/>
            </div>
            <div className="textareaofser">
                <h4>PPH(Postpartum Haemorrhage)</h4>
                <p>Postpartum hemorrhage (PPH) is defined as excessive bleeding from the birth canal following childbirth. It is a potentially life-threatening condition and is one of the leading causes of maternal mortality worldwide. Here are some important points about postpartum hemorrhage:</p>
                <h5>1. Definition: </h5>
                <p>Postpartum hemorrhage is typically defined as blood loss of 500 milliliters or more within 24 hours after a vaginal delivery or blood loss of 1,000 milliliters or more after a cesarean section. However, any amount of bleeding that causes hemodynamic instability or requires medical intervention is considered significant.</p>
                <h5>2. Causes:</h5>
                <p>PPH can occur due to various factors, including:</p>
                <h6>Uterine atony: </h6>
                <p>This is the most common cause, accounting for about 70% of PPH cases. It occurs when the uterus fails to contract effectively after delivery, leading to excessive bleeding.</p>
                <h6>Retained placenta or placental fragments:</h6>
                <p>If the placenta or fragments of it remain in the uterus after delivery, it can cause persistent bleeding.</p>
                <h6>Genital tract trauma: </h6>
                <p>Tears or lacerations of the birth canal, cervix, or perineum can result in bleeding.</p>
                <h6>Uterine rupture:</h6>
                <p>This is a rare but severe complication where the uterus tears open, leading to significant bleeding.</p>
                <h6>Coagulation disorders:</h6>
                <p>Conditions that affect blood clotting, such as von Willebrand disease or disseminated intravascular coagulation (DIC), can contribute to postpartum hemorrhage.</p>
                <h5>3. Management:</h5>
                <p>Prompt management of PPH is crucial to prevent severe complications. The management may include:</p>
                <h6>Uterine massage:</h6>
                <p>Gentle massage of the uterus helps stimulate contraction and control bleeding.</p>
                <h6>Medications:</h6>
                <p>Medications like oxytocin or other uterotonic drugs are often administered to promote uterine contractions.</p>
                <h6>Manual removal of placenta or retained fragments:</h6>
                <p>If the placenta or fragments remain inside the uterus, they may need to be manually removed.</p>
                <h6>Compression techniques:</h6>
                <p>Applying pressure on the uterus or using specific devices like a balloon tamponade can help control bleeding.</p>
                <h5>4. Prevention:</h5>
                <p>Efforts to prevent PPH include careful management of labor, early recognition of risk factors, and administration of medications to aid uterine contraction. Active management of the third stage of labor, which involves giving uterotonic medications and controlled cord traction, has been shown to reduce the risk of PPH.</p>
                <p>It is important for healthcare providers to be vigilant during the postpartum period and monitor for signs of excessive bleeding. Prompt recognition and appropriate management of PPH can significantly reduce morbidity and mortality associated with this condition.</p>
            </div>
        </div>
        </>)
}
export default memo(PIH)
import { memo, useContext, useEffect, useState } from "react"
import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css"
import ".././inner.css"
import Servicebox from "./Servicebox"
import Servicebanner from "./Servicebanner"
import Scrolltop from "../Scrolltop"
import Mobcontroller from "../Mainbox/ContactButton/Mobcontroller"
import ContactButton from "../Mainbox/ContactButton/ContactButton"
import Mobmenu from "../Mobmenu"
import Footer from "../Mainbox/Footer"
import { data,data1 } from "../../App"
import Subjectservice from "./Subjectservice"

function Service(){
    useEffect(()=>{
        window.scrollTo({top:"0px"})
    },[])
    const [menu, setMenu]=useState(Subjectservice);
    const serviceseta=useContext(data);
    useEffect(()=>{
        const update=Subjectservice.filter((check)=>{
            return check.name===serviceseta;
        })
        setMenu(update)
    })
    return (<>
    <Scrolltop/>
    <Mobcontroller/>
    <ContactButton/>
    <Mobmenu/>
    <div>
    {
        menu.map((elem)=>{
            const {name, bannerimg, bannername, service}=elem
            {
                return (<>
                <Servicebanner bannerimg={bannerimg} bannername={bannername}/>
                 <div className="serviceouter">  
                     <Servicebox service={service}/>
                 </div>
                </>)
            }
        })
    }
    </div>
    <Footer/>
    </>)
}
export default memo(Service)
import { memo, useEffect } from "react";
import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import ".././inner.css";
import { LazyLoadImage } from "react-lazy-load-image-component";

function InevitableAbortion() {
  useEffect(() => {
    document.title = "Abortion clinic in JP Nagar";
    const newMetaDescription =
      "We are the best abortion clinic in JP Nagar. We give the best care to the patient and also guide them through the process.";
    const metaTag = document.querySelector('meta[name="description"]');
    if (metaTag) {
      metaTag.setAttribute("content", newMetaDescription);
    }
  }, []);
  return (
    <>
      <h2 style={{ fontSize: "0px" }}>Abortion clinic in JP Nagar</h2>
      <div className="serinfobox">
        <div className="serinfoimg">
          <LazyLoadImage src="../photo/serv.jpg" />
        </div>
        <div className="textareaofser">
          <h4>Inevitable Abortion</h4>
          <p>
            In medical terminology, "inevitable abortion" refers to a situation
            where a miscarriage is in progress and cannot be prevented or
            stopped. It is also sometimes called an "imminent miscarriage" or a
            "threatened miscarriage with inevitable outcome." It means that the
            pregnancy is not viable, and the loss of the fetus is unavoidable.
          </p>
          <p>
            During an inevitable abortion, a woman may experience symptoms such
            as vaginal bleeding, cramping, and the passing of tissue or blood
            clots. These signs indicate that the body is expelling the contents
            of the uterus. It's important for women experiencing these symptoms
            to seek medical attention for proper evaluation and support.
          </p>
          <p>
            In some cases, healthcare providers may recommend a procedure called
            a dilation and curettage (D&C) to remove the remaining tissue from
            the uterus. This procedure helps ensure that the uterus is empty and
            reduces the risk of complications such as infection.
          </p>
          <p>
            Experiencing an inevitable abortion can be emotionally challenging
            for women and their partners. It's important to seek emotional
            support from loved ones, healthcare professionals, or support groups
            to cope with the loss and the associated feelings of grief.
          </p>
          <p>
            It's worth noting that if you or someone you know is experiencing
            symptoms of an inevitable abortion, it's crucial to consult a
            healthcare provider for an accurate diagnosis and appropriate care.
            They can provide guidance, support, and medical interventions as
            needed. Looking for the best abortion clinic in JP Nagar? Then look
            no fur and visit us today.
          </p>
        </div>
      </div>
    </>
  );
}
export default memo(InevitableAbortion);

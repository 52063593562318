import { memo, useEffect } from "react";
import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import ".././inner.css";
import { LazyLoadImage } from "react-lazy-load-image-component";

function PIH() {
  useEffect(() => {
    document.title = "PIH treatment in JP Nagar";
    const newMetaDescription =
      "We have the best gynecologist in JP Nagar for the best PIH treatment in JP Nagar. Visit us today to know more.";
    const metaTag = document.querySelector('meta[name="description"]');
    if (metaTag) {
      metaTag.setAttribute("content", newMetaDescription);
    }
  }, []);
  return (
    <>
      <h2 style={{ fontSize: "0px" }}>PIH treatment in JP Nagar</h2>
      <div className="serinfobox">
        <div className="serinfoimg">
          <LazyLoadImage src="../photo/serm.jpg" />
        </div>
        <div className="textareaofser">
          <h4>PIH(Pregnancy Induced Hypertension)</h4>
          <p>
            Pregnancy-induced hypertension (PIH), also known as gestational
            hypertension, is a condition characterized by high blood pressure
            that develops during pregnancy. It typically occurs after the 20th
            week of pregnancy and resolves after childbirth. Looking for the
            best PIH treatment in JP Nagar? Then look no fur and visit us today.
          </p>
          <h6>Definition:</h6>
          <p>
            {" "}
            PIH is diagnosed when a pregnant woman develops high blood pressure
            (blood pressure of 140/90 mmHg or higher) without the presence of
            proteinuria (protein in the urine), which distinguishes it from
            preeclampsia (a more severe condition that involves both high blood
            pressure and proteinuria).
          </p>
          <h6>Risk factors:</h6>
          <p>
            Some factors increase the risk of developing PIH, including being a
            first-time mother, having a family history of hypertension, being
            overweight or obese before pregnancy, having a multiple pregnancy
            (twins, triplets), being older than 35 years, and having certain
            pre-existing medical conditions like diabetes or kidney disease.
          </p>
          <h6>Symptoms:</h6>
          <p>
            PIH may not present with any noticeable symptoms, especially in its
            early stages. Some women may experience symptoms such as persistent
            headaches, visual disturbances, abdominal pain, and swelling of the
            hands, feet, or face.
          </p>
          <h6>Complications:</h6>
          <p>
            If left untreated, PIH can progress to preeclampsia, a more severe
            condition that can lead to complications for both the mother and the
            baby. These complications may include impaired kidney function,
            liver dysfunction, placental abruption, fetal growth restriction,
            preterm birth, and in severe cases, eclampsia (seizures).
          </p>
          <h6>Diagnosis:</h6>
          <p>
            PIH is diagnosed based on repeated blood pressure measurements
            showing sustained elevated levels (at least two readings taken at
            least four hours apart). Other diagnostic tests, such as urine
            analysis, blood tests, and ultrasound examinations, may be performed
            to rule out other conditions and monitor the well-being of the
            mother and the baby.
          </p>
          <h6>Management:</h6>
          <p>
            The management of PIH aims to control blood pressure and prevent
            complications. Treatment may include lifestyle modifications, such
            as adequate rest, regular prenatal care, maintaining a healthy diet,
            avoiding excessive salt intake, and monitoring fluid intake. In some
            cases, medication may be prescribed to lower blood pressure.
          </p>
          <h6>Monitoring and follow-up:</h6>
          <p>
            Women with PIH require close monitoring during pregnancy. This may
            involve more frequent prenatal visits to monitor blood pressure,
            assess fetal growth and well-being, and evaluate for any signs of
            preeclampsia. Depending on the severity of the condition,
            hospitalization may be necessary.
          </p>
          <p>
            It's important for pregnant women to attend regular prenatal care
            appointments to monitor their blood pressure and overall health. Any
            concerns or symptoms should be promptly reported to the healthcare
            provider for evaluation and appropriate management. Early detection
            and proper management of PIH are crucial in reducing the risk of
            complications and ensuring a healthy pregnancy.
          </p>
        </div>
      </div>
    </>
  );
}
export default memo(PIH);

import { memo, useEffect } from "react";
import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import ".././inner.css";
import { LazyLoadImage } from "react-lazy-load-image-component";

function BackPain() {
  useEffect(() => {
    document.title = "Back pain treatment in Jayanagar";
    const newMetaDescription =
      "Looking for back pain treatment in Jayanagar? Then you are in the right place. We have the best orthopedic doctor. Visit us today.";
    const metaTag = document.querySelector('meta[name="description"]');
    if (metaTag) {
      metaTag.setAttribute("content", newMetaDescription);
    }
  }, []);
  return (
    <>
      <h2 style={{ fontSize: "0px" }}>Back pain treatment in Jayanagar</h2>
      <div className="serinfobox">
        <div className="serinfoimg">
          <LazyLoadImage src="../photo/sere.jpg" />
        </div>
        <div className="textareaofser">
          <h4>Back Pain And Disc Related Diseases Treatment</h4>
          <h5>1. Conservative treatments: </h5>
          <h6>Rest and activity modification: </h6>
          <p>
            Avoiding activities that worsen the pain and allowing the back to
            rest can promote healing.
          </p>
          <h6>Physical therapy:</h6>
          <p>
            Exercises and stretches can help strengthen the muscles supporting
            the spine, improve flexibility, and alleviate pain.
          </p>
          <h6>Pain medication: </h6>
          <p>
            Over-the-counter nonsteroidal anti-inflammatory drugs (NSAIDs) can
            help reduce pain and inflammation. In some cases, stronger
            prescription medications may be necessary.
          </p>
          <h6>Heat or cold therapy: </h6>
          <p>
            Applying heat or cold packs to the affected area can help reduce
            pain and inflammation.
          </p>
          <h6>Epidural steroid injections: </h6>
          <p>
            Injections of corticosteroids into the epidural space around the
            spinal cord can provide temporary relief from pain and inflammation.
          </p>
          <h5>2. Surgical interventions:</h5>
          <h6>Discectomy: </h6>
          <p>
            In cases of a herniated or bulging disc causing severe pain or nerve
            compression, a discectomy may be performed. This procedure involves
            removing a portion of the disc to relieve pressure on the nerves.
          </p>
          <h6>Spinal fusion: </h6>
          <p>
            In cases of severe disc degeneration or instability, spinal fusion
            may be recommended. This procedure involves fusing two or more
            vertebrae together to provide stability and alleviate pain.
          </p>
          <h6>Artificial disc replacement: </h6>
          <p>
            Instead of fusion, an artificial disc can be implanted to maintain
            motion at the affected disc level while providing pain relief.
          </p>
          <h5>3. Other treatments:</h5>
          <h6>Chiropractic care:</h6>
          <p>
            Spinal adjustments and other manual therapies provided by
            chiropractors can help relieve pain and improve spinal alignment.
          </p>
          <h6>Acupuncture:</h6>
          <p>
            This alternative therapy involves the insertion of thin needles into
            specific points on the body to alleviate pain and promote healing.
          </p>
          <h6>TENS (transcutaneous electrical nerve stimulation): </h6>
          <p>
            This therapy uses a device that delivers low-voltage electrical
            currents to the affected area, helping to reduce pain.
          </p>
          <p>
            It's important to note that treatment approaches can vary based on
            individual circumstances, and a healthcare professional should
            evaluate your condition and provide personalized recommendations.
            They will consider factors such as the severity of the condition,
            your overall health, and your treatment preferences. We have the
            best orthopedic doctor for all kind of back pain treatment in
            Jayanagar. Visit us today.
          </p>
        </div>
      </div>
    </>
  );
}
export default memo(BackPain);

import { memo, useContext } from "react"
import "../../node_modules/bootstrap/dist/css/bootstrap.min.css"
import "./inner.css"
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Link } from "react-router-dom";
import { data1 } from "../App";

function Mobfourlist(props){
    const setServiceseta=useContext(data1)
    const changer={
        display:`${props.four}`
    }
    function fourer(){
        props.setTop("block")
        props.setSecond("none")
        props.setThree("none")
        props.setFour("none")
    }
return (<>
    <div style={changer}>
    <div className="mobbag">
        <div className="row">
        <div className="col-lg-3 col-md-6">
                    <div>
                    <button className="footinfo backback">
                            <div className="arrowicon mobicocrn">
                                <LazyLoadImage className="arrowbar" src="../photo/clinic.png"/>
                            </div>
                            <h6>Services in clinic</h6>
                        </button>
                        <Link onClick={()=>{
                                        setServiceseta("High intensity digital xray")
                                        props.setFirst("none")
                                        props.setTop("block")
                                        props.setFour("none")
                                      }} to="/service/highintensity" className="footinfo cenmenmob">
                            <div className="arrowicon">
                                <LazyLoadImage className="arrowbar" src="../photo/arrow-right.png"/>
                            </div>
                            <h6>High intensity digital xray</h6>
                        </Link>
                        <Link onClick={()=>{
                                        setServiceseta("Laboratory blood collection centre")
                                        props.setFirst("none")
                                        props.setTop("block")
                                        props.setFour("none")
                                      }} to="/service/laboratory" className="footinfo cenmenmob">
                            <div className="arrowicon">
                                <LazyLoadImage className="arrowbar" src="../photo/arrow-right.png"/>
                            </div>
                            <h6>Laboratory blood collection centre</h6>
                        </Link>
                        <Link onClick={()=>{
                                        setServiceseta("Wound dressing")
                                        props.setFirst("none")
                                        props.setTop("block")
                                        props.setFour("none")
                                      }} to="/service/wounddressing" className="footinfo cenmenmob">
                            <div className="arrowicon">
                                <LazyLoadImage className="arrowbar" src="../photo/arrow-right.png"/>
                            </div>
                            <h6>Wound dressing</h6>
                        </Link>
                    </div>
                </div>
        </div>
    </div>
    <button onClick={fourer} className="footinfo backback">
                            <div className="arrowicon mobicocrn">
                                <LazyLoadImage className="arrowbar" src="../photo/error.png"/>
                            </div>
                            <h6>Back</h6>
                        </button>
    </div>
</>)
}
export default memo(Mobfourlist);
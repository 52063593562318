import { memo, useEffect } from "react";
import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import ".././inner.css";
import { LazyLoadImage } from "react-lazy-load-image-component";

function Serinfobox() {
  useEffect(() => {
    document.title = "Bone fracture treatment in Bannerghatta main road";
    const newMetaDescription =
      "Looking for the best orthopedic doctor in Bannerghatta main road for the best Bone fracture treatment in Bannerghatta main road? Then visit us today.";
    const metaTag = document.querySelector('meta[name="description"]');
    if (metaTag) {
      metaTag.setAttribute("content", newMetaDescription);
    }
  }, []);
  return (
    <>
      <h2 style={{ fontSize: "0px" }}>
        Bone fracture treatment in Bannerghatta main road
      </h2>
      <div className="serinfobox">
        <div className="serinfoimg">
          <LazyLoadImage src="../photo/sera.jpg" />
        </div>
        <div className="textareaofser">
          <h4>Bone Fracture Treatment Surgical And Conservative Treatment</h4>
          <p>
            When it comes to treating bone fractures, there are two main
            approaches: surgical treatment and conservative treatment. The
            choice of treatment depends on various factors such as the type and
            location of the fracture, the age and overall health of the patient,
            and the preferences of the treating physician. Let's explore each
            treatment option in more detail but before that if you are looking
            for the best orthopedic doctor in Bannerghatta main road for the
            best Bone fracture treatment in Bannerghatta main road? Then visit
            us today.
          </p>
          <h5>1) Conservative Treatment:</h5>
          <p>
            Conservative treatment involves non-surgical methods to promote
            healing and immobilize the fractured bone. It typically includes the
            following:
          </p>
          <h6>a. Immobilization: </h6>
          <p>
            The primary goal is to stabilize the fracture and prevent further
            movement that could impede healing. This can be achieved using
            casts, splints, or braces, depending on the location and severity of
            the fracture.
          </p>
          <h6>b. Pain Management:</h6>
          <p>
            Over-the-counter or prescription pain medications may be prescribed
            to alleviate pain associated with the fracture.
          </p>
          <h6>c. Physical Therapy:</h6>
          <p>
            Once the fracture shows signs of healing, physical therapy exercises
            and rehabilitation may be recommended to restore strength,
            flexibility, and mobility to the affected area.
          </p>
          <h6>d. Regular Follow-up:</h6>
          <p>
            Consistent monitoring of the fracture's progress through periodic
            X-rays or other imaging techniques is essential to ensure proper
            healing. Conservative treatment is generally preferred for stable
            fractures, certain types of nondisplaced fractures, or fractures in
            individuals who may not be suitable candidates for surgery.
          </p>
          <h5>2) Surgical Treatment:</h5>
          <p>
            Surgical treatment involves the use of surgical techniques to
            realign the fractured bone fragments and provide stabilization. This
            approach is usually chosen for more complex fractures or when
            conservative methods are unlikely to yield satisfactory results.
            Surgical treatment may include the following:
          </p>
          <h6>a. Open Reduction and Internal Fixation (ORIF):</h6>
          <p>
            In this procedure, the fracture fragments are repositioned
            surgically, and internal fixation devices such as screws, plates, or
            rods are used to hold the bone in place during the healing process.
          </p>
          <h6>b. External Fixation: </h6>
          <p>
            This method involves the use of external devices, such as pins or
            wires, which are placed into the bone on either side of the
            fracture. These devices are then connected to an external frame,
            stabilizing the fracture from the outside.
          </p>
          <h6>c. Intramedullary Nailing:</h6>
          <p>
            It is a procedure where a metal rod (nail) is inserted into the
            marrow cavity of the fractured bone to provide stability and
            alignment.
          </p>
          <h6>d. Bone Grafting:</h6>
          <p>
            In cases where there is a gap between the fractured bone ends or if
            the bone has been severely damaged, a bone graft may be performed.
            This involves transplanting bone tissue from another part of the
            body or using artificial bone substitutes to stimulate healing and
            bridge the gap. Surgical treatment allows for more precise
            realignment of the bone fragments and provides immediate stability,
            promoting faster healing in complex fractures, open fractures, or
            cases with significant displacement.
          </p>
          <p>
            It's important to note that the specific treatment plan for a bone
            fracture will be determined by a healthcare professional based on
            individual circumstances. The decision between surgical and
            conservative treatment is made after a thorough evaluation of the
            fracture and consideration of the patient's overall health and
            preferences.
          </p>
        </div>
      </div>
    </>
  );
}
export default memo(Serinfobox);

import { Link } from "react-router-dom";
import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css"
import ".././inner.css"
import Carousel from 'react-bootstrap/Carousel';
import { memo, useState } from "react";

function Slider() {
  return (
    <Carousel>
      <Carousel.Item>
      <div className="big">
        <img
          className="d-block w-100"
          src="../photo/under.jpg"
          alt="First slide"
        />
      </div>
      <div className="small">
        <img
          className="d-block w-100"
          src="../photo/fister.jpg"
          alt="First slide"
        />
        </div>
        <Carousel.Caption>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
      <div className="big">
        <img
          className="d-block w-100"
          src="../photo/svchgsgfvhsc.jpg"
          alt="First slide"
        />
      </div>
      <div className="small">
        <img
          className="d-block w-100"
          src="../photo/server.jpg"
          alt="First slide"
        />
        </div>
        <Carousel.Caption>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
      <div className="big">
        <img
          className="d-block w-100"
          src="../photo/oooooo.jpg"
          alt="Second slide"
        />
        </div>
      <div className="small">
        <img
          className="d-block w-100"
          src="../photo/ttttt.jpg"
          alt="First slide"
        />
        </div>

        <Carousel.Caption>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
      <div className="big">
        <img
          className="d-block w-100"
          src="../photo/oooo.jpg"
          alt="First slide"
        />
      </div>
      <div className="small">
        <img
          className="d-block w-100"
          src="../photo/ooooo.jpg"
          alt="First slide"
        />
        </div>
        <Carousel.Caption>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
      <div className="big">
        <img
          className="d-block w-100"
          src="../photo/fine.jpg"
          alt="Second slide"
        />
        </div>
      <div className="small">
        <img
          className="d-block w-100"
          src="../photo/subbannertwo.jpg"
          alt="First slide"
        />
        </div>

        <Carousel.Caption>
        </Carousel.Caption>
      </Carousel.Item>
    </Carousel>
  );
}

export default memo(Slider);
import { memo, useEffect, useState } from "react"
import { LazyLoadImage } from "react-lazy-load-image-component";
import "../../node_modules/bootstrap/dist/css/bootstrap.min.css"
import "./inner.css"

function Scrolltop(){
    const [dis, setDis]=useState("none")
    useEffect(()=>{
        window.addEventListener("scroll",()=>{
            if(window.scrollY>500){
                setDis("block")
            }else{
                setDis("none")
            }
        })
    })
    function scrollst(){
        window.scrollTo({top:"0px",behavior:"smooth"})
    }
    const changer={
        display:`${dis}`,
    }
    return (<>
    <div style={changer} className="srolltopbut">
        <LazyLoadImage onClick={scrollst} className="scrollimg" src="../photo/up-arrow.png"/>
    </div>
    </>)
}
export default memo(Scrolltop);
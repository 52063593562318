import { memo } from "react"

function Galleryve(){
    return (<>
    <div className="container">
    <div className="row orthocen">
                <h1 style={{color:"black"}}>Video Gallery</h1>
            </div>
        <div className="row">
            <div className="col-xl-4 col-sm-6 col-12 mt-2">
                <video className="w-100" controls src="../photo/vediootwo.mp4">
                </video>
            </div>
            <div className="col-xl-4 col-sm-6 col-12 mt-2">
                <video className="w-100" controls src="../photo/vedieoone.mp4">
                </video>
            </div>
            <div className="col-xl-4 col-sm-6 col-12 mt-2">
                <video className="w-100" controls src="../photo/control.mp4">
                </video>
            </div>
        </div>
    </div>
    </>)
}
export default memo(Galleryve)
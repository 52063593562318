import { memo } from "react"
import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css"
import ".././inner.css"

function ContactBox(){
    return (<>
    <div className="sectionmar">
         <div className="container conbox">
            <div className="imgcont">
                <div className="imgconbox">
                    <img src="../photo/phone.png" alt="" />
                </div>
                <div className="context">
                    <h6>Please contact us with your question and needs, our team<br className="conbr"></br> would be delighted to assist you promptly</h6>
                </div>
            </div>
            <div className="condiv">
                <a href="tel:84310 69548">
                    <div className="conconbox">
                        <h5>Contact Us</h5>
                    </div>
                </a>
            </div>
         </div>
    </div>
    </>)
}
export default memo(ContactBox)
import { memo, useEffect } from "react";
import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import ".././inner.css";
import { LazyLoadImage } from "react-lazy-load-image-component";

function Sportmedicine() {
  useEffect(() => {
    document.title = "Sports medicine doctor in Jayanagar";
    const newMetaDescription =
      "Looking for the best Sports medicine doctor in Jayanagar? Then look no fur & visit us today for the best treatment.";
    const metaTag = document.querySelector('meta[name="description"]');
    if (metaTag) {
      metaTag.setAttribute("content", newMetaDescription);
    }
  }, []);
  return (
    <>
      <h2 style={{ fontSize: "0px" }}>Sports medicine doctor in Jayanagar</h2>
      <div className="serinfobox">
        <div className="serinfoimg">
          <LazyLoadImage src="../photo/serb.jpg" />
        </div>
        <div className="textareaofser">
          <h4>Sport Medicine</h4>
          <p>
            Sports medicine is a branch of medicine that focuses on the
            prevention, diagnosis, treatment, and rehabilitation of injuries and
            conditions related to physical activity, sports participation, and
            exercise. It encompasses a multidisciplinary approach, involving
            healthcare professionals from various fields working together to
            provide comprehensive care to athletes and individuals engaging in
            physical activities. We have the best sports medicine doctor in
            Jayanagar. Feel free to visit us.
          </p>
          <h5>Key Components of Sports Medicine:</h5>
          <h6>Injury Prevention:</h6>
          <p>
            Sports medicine professionals work to prevent injuries through
            education, training, and implementing preventive measures such as
            proper warm-up routines, conditioning programs, and equipment
            recommendations. They also address issues related to technique,
            biomechanics, and environmental factors.
          </p>
          <h6>Diagnosis and Treatment:</h6>
          <p>
            {" "}
            When sports-related injuries occur, sports medicine physicians are
            trained to diagnose and treat a wide range of conditions. This
            includes acute injuries like sprains, strains, fractures,
            dislocations, and concussions, as well as chronic overuse injuries
            such as tendinitis and stress fractures. Treatment methods may
            involve medication, physical therapy, rehabilitation exercises, and
            other interventions tailored to the specific injury.
          </p>
          <h6>Rehabilitation:</h6>
          <p>
            Sports medicine professionals guide athletes and individuals through
            the recovery process after an injury or surgery. They develop
            customized rehabilitation programs that include exercises,
            therapies, and modalities aimed at restoring strength, flexibility,
            and function. Rehabilitation may also involve coordination with
            physical therapists, athletic trainers, and other specialists.
          </p>
          <h6>Performance Enhancement:</h6>
          <p>
            Sports medicine professionals not only treat injuries but also work
            on optimizing athletic performance. They provide guidance on
            nutrition, strength and conditioning, injury prevention strategies,
            and performance-enhancing techniques. They may collaborate with
            coaches, trainers, and athletes to develop training programs and
            enhance athletic performance.
          </p>
          <h6>Concussion Management:</h6>
          <p>
            Concussions are a common sports-related injury. Sports medicine
            physicians play a crucial role in the assessment, diagnosis, and
            management of concussions. They help with the proper evaluation of
            symptoms, neurocognitive testing, and stepwise return-to-play
            protocols to ensure a safe and gradual return to activity.
          </p>
          <h6>Team and Event Coverage:</h6>
          <p>
            Sports medicine professionals often provide medical coverage for
            sports teams, athletic events, and competitions. They may be present
            on the sidelines or in the medical tent to evaluate and manage acute
            injuries, provide immediate care, and make return-to-play decisions.
          </p>
          <p>
            Sports medicine is not limited to professional athletes but extends
            to individuals of all ages and activity levels, including
            recreational athletes, weekend warriors, and physically active
            individuals. The goal is to promote overall health, prevent
            injuries, manage conditions, and optimize performance to support an
            active and physically fit lifestyle.
          </p>
        </div>
      </div>
    </>
  );
}
export default memo(Sportmedicine);

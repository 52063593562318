import { memo, useState } from "react"
import "../../../../node_modules/bootstrap/dist/css/bootstrap.min.css"
import "../.././inner.css"
import { LazyLoadImage } from "react-lazy-load-image-component"

function ContactForm(props){
    const [name, setName]=useState("");
    const [email, setEmail]=useState("")
    const [phone, setPhone]=useState("")
    const [message, setMessage]=useState("")
    function helloo(){
        var url="https://wa.me/91431069548?text="+
		"Name: "+name+"%0a"
		+"Email: "+email+"%0a"
		+"Phone: "+phone+"%0a"
		+"Message: "+message;
		window.open(url,"_blank").focus();
    }
    const stayler={
        display:`${props.formcontrol}`
    }
    function changer(){
        props.setFormcontrol("none")
    }
    window.addEventListener("resize",()=>{
        if(window.innerWidth<768){
            props.setFormcontrol("none")
        }
    })
    return (<>
        <div style={stayler} className="conforlas">
        <form className="innerform">
            <div className="row contitle">
                <h5>Contact Us</h5>
            </div>
            <div>
                <label><h6>Your Name</h6></label><br></br>
                <input onChange={(e)=>{setName(e.target.value)}} className="inconlas"></input><br></br>
                <label><h6>Your Email</h6></label><br></br>
                <input onChange={(e)=>{setEmail(e.target.value)}} className="inconlas"></input><br></br>
                <label><h6>Phone Number</h6></label><br></br>
                <input onChange={(e)=>{setPhone(e.target.value)}} className="inconlas"></input><br></br>
                <label><h6>Message</h6></label><br></br>
                <textarea onClick={helloo} onChange={(e)=>{setMessage(e.target.value)}} className="meassageconfor" name="" id="" cols="30" rows="10"></textarea>
            </div>
            <div className="subconfor">
                <button className="subformbox">Submit</button>
            </div>
        </form>
        <div onClick={changer} className="errorbox">
            <LazyLoadImage src="../photo/error.png"></LazyLoadImage>
        </div>
        </div>
        </>)
}
export default memo(ContactForm)
import { memo, useState } from "react"
import "../../../../node_modules/bootstrap/dist/css/bootstrap.min.css"
import "../.././inner.css"
import ContactForm from "./ContactForm";
import Controller from "./Controller";
function ContactButton(){
    const [formcontrol, setFormcontrol]=useState("none")

    return (<>
    <Controller formcontrol={formcontrol} setFormcontrol={setFormcontrol}/>
    <ContactForm  formcontrol={formcontrol} setFormcontrol={setFormcontrol}/>
    </>)
}
export default memo(ContactButton);
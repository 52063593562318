import { memo, useEffect } from "react";
import Addressinfo from "../Mainbox/Addressinfo";
import ContactButton from "../Mainbox/ContactButton/ContactButton";
import Mobcontroller from "../Mainbox/ContactButton/Mobcontroller";
import Contactbox from "../Mainbox/Contactbox";
import Footer from "../Mainbox/Footer";
import Hospitalinfo from "../Mainbox/Hospitalinfo";
import Review from "../Mainbox/Review";
import Mobmenu from "../Mobmenu";
import Scrolltop from "../Scrolltop";
import Aboutusbanner from "./Aboutusbanner";

function About() {
  useEffect(() => {
    window.scrollTo({ top: "0px" });

    document.title = "Best multispecialty hospital in Jayanagar";
    const newMetaDescription =
      "Looking for the best multispecialty hospital in Jayanagar? Then look no fur and visit us today.";
    const metaTag = document.querySelector('meta[name="description"]');
    if (metaTag) {
      console.log(metaTag);
      metaTag.setAttribute("content", newMetaDescription);
    }
  }, []);
  return (
    <>
      <h2 style={{ fontSize: "0px" }}>
        Best multispecialty hospital in Jayanagar
      </h2>
      <Scrolltop />
      <Mobcontroller />
      <ContactButton />
      <Mobmenu />
      <Aboutusbanner />
      <div className="abouthospi">
        <Hospitalinfo />
      </div>
      <div className="backconadd">
        <Contactbox />
        <div className="aboutadd">
          <Addressinfo />
        </div>
      </div>
      <Review />
      <Footer />
    </>
  );
}
export default memo(About);

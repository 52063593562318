import { memo, useState } from "react"
import "../../node_modules/bootstrap/dist/css/bootstrap.min.css"
import "./inner.css"
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Link } from "react-router-dom";

function Mobfirstlist(props){
    const [our, setOur]=useState("none")
    const [oura, setOura]=useState(true)
    const firstlist={
        display:`${props.top}`
    }
    function changer(){
        props.setFirst("none")
    }
    function seconder(){
        props.setTop("none")
        props.setSecond("block")
        props.setThree("none")
        props.setFour("none")
    }
    function threeder(){
        props.setTop("none")
        props.setSecond("none")
        props.setThree("block")
        props.setFour("none")
    }
    function fourer(){
        props.setTop("none")
        props.setSecond("none")
        props.setThree("none")
        props.setFour("block")
    }
    const chain={
        display:`${our}`
    }
    return (<>
    <div style={firstlist}>
    <div className="mobbag">
        <div className="row">
        <div className="col-lg-3 col-md-6">
                    <div>
                        <Link to="/" className="footinfo cenmenmob">
                            <div className="arrowicon">
                                <LazyLoadImage className="arrowbar" src="../photo/arrow-right.png"/>
                            </div>
                            <h6>Home</h6>
                        </Link>
                        <Link to="/about" className="footinfo cenmenmob">
                            <div className="arrowicon">
                                <LazyLoadImage className="arrowbar" src="../photo/arrow-right.png"/>
                            </div>
                            <h6>About Us</h6>
                        </Link>
                        <button onClick={()=>{
                            if(oura){
                            setOur("block")
                            setOura(!oura)
                            }else{
                            setOur("none")
                            setOura(!oura)
                            }
                        }} className="footinfo cenmenmob">
                            <div className="arrowicon">
                                <LazyLoadImage className="arrowbar" src="../photo/arrow-right.png"/>
                            </div>
                            <h6>Our Doctor</h6>
                        </button>
                        
                        <div style={chain} className="mobsubmenu">
                            <Link to="/drchethankumarr" className="footinfo cenmenmob">
                            <div className="arrowicon">
                                <LazyLoadImage className="arrowbar" src="../photo/arrow-right.png"/>
                            </div>
                            <h6>Dr Chethan Kumar R</h6>
                           </Link>
                           <Link to="/drkrupankabv" className="footinfo cenmenmob">
                            <div className="arrowicon">
                                <LazyLoadImage className="arrowbar" src="../photo/arrow-right.png"/>
                            </div>
                            <h6>Dr Krupanka BV</h6>
                            </Link>
                        </div>
                        <button className="footinfo cenmenmob">
                            <div className="arrowicon">
                                <LazyLoadImage className="arrowbar" src="../photo/arrow-right.png"/>
                            </div>
                            <h6>Services</h6>
                        </button>
                        <div className="mobsubmenu">
                            <button onClick={seconder} className="footinfo cenmenmob">
                            <div className="arrowicon">
                                <LazyLoadImage className="arrowbar" src="../photo/joint.png"/>
                            </div>
                            <h6>Orthopedic Services</h6>
                           </button>
                           <button onClick={threeder} className="footinfo cenmenmob">
                            <div className="arrowicon">
                                <LazyLoadImage className="arrowbar" src="../photo/fallopian-tubes.png"/>
                            </div>
                            <h6>OBG Services</h6>
                            </button>
                            <button onClick={fourer} className="footinfo cenmenmob">
                            <div className="arrowicon">
                                <LazyLoadImage className="arrowbar" src="../photo/clinic.png"/>
                            </div>
                            <h6>Services in clinic</h6>
                           </button>
                        </div>
                        <Link to="/gallery" className="footinfo cenmenmob">
                            <div className="arrowicon">
                                <LazyLoadImage className="arrowbar" src="../photo/arrow-right.png"/>
                            </div>
                            <h6>Gallery</h6>
                        </Link>
                        <Link to="/contact" className="footinfo cenmenmob">
                            <div className="arrowicon">
                                <LazyLoadImage className="arrowbar" src="../photo/arrow-right.png"/>
                            </div>
                            <h6>Contact Us</h6>
                        </Link>
                    </div>
                </div>
        </div>
    </div>
    <button onClick={changer} className="footinfo backback">
                            <div className="arrowicon mobicocrn">
                                <LazyLoadImage className="arrowbar" src="../photo/error.png"/>
                            </div>
                            <h6>Back</h6>
                        </button>
    </div>
    </>)
}
export default memo(Mobfirstlist);
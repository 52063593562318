import { memo, useEffect } from "react";
import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import ".././inner.css";
import { LazyLoadImage } from "react-lazy-load-image-component";

function OrthopedicProblem() {
  useEffect(() => {
    document.title =
      "Orthopedic problem during pregnancy treatment in Jayanagar";
    const newMetaDescription =
      "We have the best gynecologist in Jayanagar for the best Orthopedic problem during pregnancy treatment in Jayanagar.";
    const metaTag = document.querySelector('meta[name="description"]');
    if (metaTag) {
      metaTag.setAttribute("content", newMetaDescription);
    }
  }, []);
  return (
    <>
      <h2 style={{ fontSize: "0px" }}>
        Orthopedic problem during pregnancy treatment in Jayanagar
      </h2>
      <div className="serinfobox">
        <div className="serinfoimg">
          <LazyLoadImage src="../photo/sert.jpg" />
        </div>
        <div className="textareaofser">
          <h4>Orthopedic Problem With Pregnancy</h4>
          <p>
            Pregnancy can sometimes contribute to or exacerbate orthopedic
            problems or musculoskeletal conditions. The changes that occur in a
            woman's body during pregnancy, such as weight gain, hormonal
            fluctuations, and shifts in the center of gravity, can place
            additional stress on the musculoskeletal system. We have the best
            gynecologist in Jayanagar for the best Orthopedic problem during
            pregnancy treatment in Jayanagar.
          </p>
          <h6>Low back pain:</h6>
          <p>
            The weight gain and changes in posture during pregnancy can strain
            the lower back and lead to discomfort or pain. Hormonal changes,
            particularly the hormone relaxin, can also affect the ligaments and
            joints, potentially contributing to low back pain.
          </p>
          <h6>Pelvic girdle pain:</h6>
          <p>
            Pregnancy hormones can loosen the ligaments that support the pelvis,
            leading to instability and pain in the pelvic region. This
            condition, known as pelvic girdle pain or symphysis pubis
            dysfunction, can cause pain in the pubic area, hips, and lower back.
          </p>
          <h6>Carpal tunnel syndrome:</h6>
          <p>
            Some pregnant women may experience symptoms of carpal tunnel
            syndrome, which involves compression of the median nerve in the
            wrist. Swelling and fluid retention during pregnancy can contribute
            to this condition, leading to symptoms such as pain, numbness, and
            tingling in the hands and fingers.
          </p>
          <h6>Joint pain and ligament strain:</h6>
          <p>
            The hormonal changes in pregnancy can affect the integrity of
            ligaments and joints, making them more prone to strain or injury.
            Weight gain and the increased load on the joints can also contribute
            to joint pain, particularly in weight-bearing areas such as the
            hips, knees, and ankles.
          </p>
          <h6>Varicose veins:</h6>
          <p>
            Varicose veins are swollen, twisted veins that can develop in the
            legs during pregnancy. The increased blood volume and pressure,
            combined with hormonal changes, can contribute to the development or
            worsening of varicose veins.
          </p>
          <h6>Diastasis recti: </h6>
          <p>
            Diastasis recti is a separation of the abdominal muscles that can
            occur during pregnancy, particularly in the third trimester. This
            can cause weakness and contribute to lower back pain and a bulging
            or doming appearance of the abdomen.
          </p>
          <p>
            If you experience significant orthopedic problems or persistent pain
            during pregnancy, it's important to consult your healthcare
            provider. They can provide guidance on managing symptoms, recommend
            appropriate exercises or physical therapy, and discuss any necessary
            interventions to ensure the well-being of both the mother and the
            baby.
          </p>
          <p>
            It's also worth noting that staying physically active, maintaining
            good posture, using proper body mechanics, and practicing gentle
            stretching exercises can help alleviate some orthopedic discomfort
            during pregnancy. However, it's important to consult with your
            healthcare provider before starting any exercise regimen or trying
            new treatments.
          </p>
        </div>
      </div>
    </>
  );
}
export default memo(OrthopedicProblem);

import { memo, useState } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Link } from "react-router-dom";

function Contactmaincon() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");
  function helloo() {
    var url =
      "https://wa.me/91431069548?text=" +
      "Name: " +
      name +
      "%0a" +
      "Email: " +
      email +
      "%0a" +
      "Phone: " +
      phone +
      "%0a" +
      "Message: " +
      message;
    window.open(url, "_blank").focus();
  }
  return (
    <>
      <section className="sectionmar mt-6 mb-6">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="hospitalin">
                {/* <h6>ABOUT US</h6> */}
                <h1>Ultimate Health Care and expertize</h1>
                <p>
                  Viksha comes with state-of-the-art world class facilities and
                  is a multispecialty Clinic situated in the 95,15th Main, 17th
                  Cross Rd, 5th Phase, J. P. Nagar, Bengaluru, Karnataka. We at
                  Viksha are making the best of the exceptionally talented
                  specialists and the latest equipment to provide you the best
                  of medical facilities to ensure best health for you.
                </p>
                <div>
                  <div className="hospbar"></div>
                  <Link className="hoslink">
                    <h6>MORE ABOUT US</h6>
                  </Link>
                </div>
              </div>
              <div className="row mt-4">
                <div className="col-lg-4 col-md-6 addinfobox addinfohover">
                  <div className="innerinfobox">
                    <div className="addbar addhover"></div>
                    <div className="textboxin">
                      <div className="addresicon concenti">
                        <LazyLoadImage src="../photo/joint.png" alt="" />
                      </div>
                      <div className="timeinfo concenti">
                        <h5>
                          Orthopedic<br></br>Consultation
                        </h5>
                      </div>
                    </div>
                    <div className="trangle"></div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6 addinfobox addinfohover">
                  <div className="innerinfobox">
                    <div className="addbar addhover"></div>
                    <div className="textboxin">
                      <div className="addresicon concenti">
                        <LazyLoadImage
                          src="../photo/fallopian-tubes.png"
                          alt=""
                        />
                      </div>
                      <div className="timeinfo concenti">
                        <h5>
                          Consultant<br></br>OBG
                        </h5>
                      </div>
                    </div>
                    <div className="trangle"></div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6 addinfobox addinfohover">
                  <div className="innerinfobox">
                    <div className="addbar addhover"></div>
                    <div className="textboxin">
                      <div className="addresicon concenti">
                        <LazyLoadImage src="../photo/clinic.png" alt="" />
                      </div>
                      <div className="timeinfo concenti">
                        <h5>
                          Clinic<br></br>timing
                        </h5>
                      </div>
                    </div>
                    <div className="trangle"></div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="conmainconin pt-4">
                <h4>Contact Us</h4>
                <div>
                  <label className="labelmaincon mt-3 mb-2">
                    <h5>Your Name</h5>
                  </label>
                  <br></br>
                  <input
                    onChange={(e) => {
                      setName(e.target.value);
                    }}
                    className="inpumaincon"
                  ></input>
                </div>
                <div>
                  <label className="labelmaincon mt-3 mb-2">
                    <h5>Your Email</h5>
                  </label>
                  <br></br>
                  <input
                    onChange={(e) => {
                      setEmail(e.target.value);
                    }}
                    className="inpumaincon"
                  ></input>
                </div>
                <div>
                  <label className="labelmaincon mt-3 mb-2">
                    <h5>Phone Number</h5>
                  </label>
                  <br></br>
                  <input
                    onChange={(e) => {
                      setPhone(e.target.value);
                    }}
                    className="inpumaincon"
                  ></input>
                </div>
                <div>
                  <label className="labelmaincon mt-3 mb-2">
                    <h5>Message</h5>
                  </label>
                  <br></br>
                  <textarea
                    onChange={(e) => {
                      setMessage(e.target.value);
                    }}
                    className="tepumaincon"
                  ></textarea>
                </div>
                <div className="subconfor">
                  <button onClick={helloo} className="subformbox mb-5 pllf">
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
export default memo(Contactmaincon);

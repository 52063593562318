import { memo } from "react"
import "../../../../node_modules/bootstrap/dist/css/bootstrap.min.css"
import "../.././inner.css"
import { LazyLoadImage } from 'react-lazy-load-image-component';
import LazyLoad from 'react-lazy-load';
function Orthopedicinfo(props){
    const twoer={
        display:`${props.one}`
    }
    function three(){
        props.setCheck(false)
    }
    function one(){
        props.setOne("Block")
        props.setTwo("none")
    }
    function two(){
        props.setOne("none")
        props.setTwo("block")
    }
    return (<>
    <section onClick={three} style={twoer} className="orthseec p-40">
        <div className="container">
        <div className="row">
        <div className="col-lg-6 cenimhdoc">
            <LazyLoadImage className="orthoimgdoc" src="../photo/onedoctor.jpg">
            </LazyLoadImage>
        </div>
        <div className="col-lg-6 textorthodoc">
            <div>
            <h1>Dr Chethan Kumar R</h1>
            <h3>MBBS, MS ORTHO, FIJR, ARTHOSCOPY & JOINT REPLACEMENT SURGEON</h3>
            <p>Dr Chethan Kumar R is an orthopaedic surgeon with specialization in joint replacement, trauma and sports related injuries. He has fellowship in joint replacement surgery from Fortis hospital. He was a meritorious student who has completed MBBS and MS orthopaedics securing top rank in entrance. He has completed AO advanced trauma course. He is specialist in primary and complex trauma, primary joint replacement hip and knee, navigated joint replacement knee, revision joint replacement and sports injuries such as acl reconstruction ,bankart repair, meniscus repair.</p>
                <div className="imgcont addwhy orthocol">
                        <div className="imgconbox">
                            <LazyLoadImage src="../photo/pin.png" alt="" />
                        </div>
                        <div className="context">
                            <h6>95,15th Main, 17th Cross Rd, 5th Phase, J. P. Nagar, Bengaluru, Karnataka 560078</h6>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </div>
        <div className="recordbox">
            <LazyLoadImage onClick={one} className="record" src="../photo/record.png"></LazyLoadImage>
            <LazyLoadImage onClick={two} className="record" src="../photo/record.png"></LazyLoadImage>
        </div>
    </section>
    </>)
}
export default memo(Orthopedicinfo);
import { memo, useEffect } from "react";
import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import ".././inner.css";
import { LazyLoadImage } from "react-lazy-load-image-component";

function Highintensity() {
  useEffect(() => {
    document.title = "Digital X Ray clinic in JP Nagar";
    const newMetaDescription =
      "We give the best digital x ray in JP Nagar. Visit us today for any kind of x ray support.";
    const metaTag = document.querySelector('meta[name="description"]');
    if (metaTag) {
      metaTag.setAttribute("content", newMetaDescription);
    }
  }, []);
  return (
    <>
      <h2 style={{ fontSize: "0px" }}>Digital X Ray clinic in JP Nagar</h2>
      <div className="serinfobox">
        <div className="serinfoimg">
          <LazyLoadImage src="../photo/serh.jpg" />
        </div>
        <div className="textareaofser">
          <h4>High intensity digital xray</h4>
          <p>
            High-intensity digital X-ray, also known as digital radiography,
            refers to an advanced imaging technique that utilizes digital
            sensors to capture X-ray images of the body. It offers several
            advantages over traditional film-based X-rays, including improved
            image quality, faster image acquisition, and the ability to enhance
            and manipulate images digitally. We give the best digital x ray in
            JP Nagar. Visit us today for any kind of x ray support.
          </p>
          <h5>
            Here are some key features and benefits of high-intensity digital
            X-ray:
          </h5>
          <h6>Digital image capture:</h6>
          <p>
            High-intensity digital X-ray systems use digital detectors or
            sensors to capture X-ray images of the body. These detectors convert
            X-ray energy into electrical signals, which are then processed and
            transformed into digital images.
          </p>
          <h6>Image quality:</h6>
          <p>
            Digital X-ray systems provide excellent image quality, with high
            resolution and detail. This allows healthcare professionals to
            visualize the internal structures of the body with greater clarity,
            aiding in the diagnosis and evaluation of various conditions.
          </p>
          <h6>Lower radiation exposure:</h6>
          <p>
            Digital X-ray systems require lower radiation doses compared to
            traditional film-based X-rays. This reduces the radiation exposure
            for both patients and healthcare providers while still obtaining
            high-quality images.
          </p>
          <h6>Faster image acquisition:</h6>
          <p>
            Digital X-ray images are acquired almost instantaneously,
            eliminating the need for film processing. This leads to shorter
            examination times and quicker access to the images for
            interpretation by radiologists or other healthcare professionals.
          </p>
          <h6>Image manipulation and enhancement:</h6>
          <p>
            Digital X-ray images can be manipulated and enhanced using
            specialized software. This includes adjusting brightness and
            contrast, zooming in on specific areas, and applying filters or
            annotations to highlight certain structures or abnormalities. These
            tools aid in the interpretation and analysis of the images.
          </p>
          <h6>Easy storage and retrieval:</h6>
          <p>
            {" "}
            Digital X-ray images can be stored electronically, eliminating the
            need for physical film storage. The images can be easily accessed,
            retrieved, and shared within a hospital or healthcare system,
            allowing for efficient collaboration and consultation among
            healthcare professionals.
          </p>
          <p>
            High-intensity digital X-ray has become increasingly prevalent in
            healthcare settings due to its numerous advantages. It enhances
            diagnostic capabilities, improves workflow efficiency, and
            contributes to more accurate and timely patient care. However, it's
            important to note that the specific features and benefits may vary
            depending on the specific digital X-ray system and technology used
            by each healthcare facility.
          </p>
        </div>
      </div>
    </>
  );
}
export default memo(Highintensity);

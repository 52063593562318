import { memo, useContext } from "react"
import "../../node_modules/bootstrap/dist/css/bootstrap.min.css"
import "./inner.css"
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Link } from "react-router-dom";
import { data1 } from "../App";

function Mobthreelist(props){
    const setServiceseta=useContext(data1)
    const changer={
        display:`${props.three}`
    }
    function thrreder(){
        props.setTop("block")
        props.setSecond("none")
        props.setThree("none")
        props.setFour("none")
    }
    return (<>
        <div style={changer}>
        <div className="mobbag">
            <div className="row">
            <div className="col-lg-3 col-md-6">
                        <div>
                        <button className="footinfo backback">
                                <div className="arrowicon mobicocrn">
                                    <LazyLoadImage className="arrowbar" src="../photo/fallopian-tubes.png"/>
                                </div>
                                <h6>OBG Services</h6>
                            </button>
                            <Link onClick={()=>{
                                        setServiceseta("General Obstetrics")
                                        props.setFirst("none")
                                        props.setTop("block")
                                        props.setThree("none")
                                      }} to="/service/generalObstetrics" className="footinfo cenmenmob">
                                <div className="arrowicon">
                                    <LazyLoadImage className="arrowbar" src="../photo/arrow-right.png"/>
                                </div>
                                <h6>General Obstetrics</h6>
                            </Link>
                            <Link onClick={()=>{
                                        setServiceseta("High Risk Obstetric Cases")
                                        props.setFirst("none")
                                        props.setTop("block")
                                        props.setThree("none")
                                      }} to="/service/highRiskObstetric" className="footinfo cenmenmob">
                                <div className="arrowicon">
                                    <LazyLoadImage className="arrowbar" src="../photo/arrow-right.png"/>
                                </div>
                                <h6>High Risk Obstetric Cases</h6>
                            </Link>
                            <Link onClick={()=>{
                                        setServiceseta("PIH(Pregnancy Induced Hypertension)")
                                        props.setFirst("none")
                                        props.setTop("block")
                                        props.setThree("none")
                                      }} to="/service/pih" className="footinfo cenmenmob">
                                <div className="arrowicon">
                                    <LazyLoadImage className="arrowbar" src="../photo/arrow-right.png"/>
                                </div>
                                <h6>PIH(Pregnancy Induced Hypertension)</h6>
                            </Link>
                            <Link onClick={()=>{
                                        setServiceseta("APH(Antepartum Haemorrhage)")
                                        props.setFirst("none")
                                        props.setTop("block")
                                        props.setThree("none")
                                      }} to="/service/aph" className="footinfo cenmenmob">
                                <div className="arrowicon">
                                    <LazyLoadImage className="arrowbar" src="../photo/arrow-right.png"/>
                                </div>
                                <h6>APH(Antepartum Haemorrhage)</h6>
                            </Link>
                            <Link onClick={()=>{
                                        setServiceseta("PPH(Postpartum Haemorrhage)")
                                        props.setFirst("none")
                                        props.setTop("block")
                                        props.setThree("none")
                                      }} to="/service/pph" className="footinfo cenmenmob">
                                <div className="arrowicon">
                                    <LazyLoadImage className="arrowbar" src="../photo/arrow-right.png"/>
                                </div>
                                <h6>PPH(Postpartum Haemorrhage)</h6>
                            </Link>
                            <Link onClick={()=>{
                                        setServiceseta("Ectopic Pregnancy")
                                        props.setFirst("none")
                                        props.setTop("block")
                                        props.setThree("none")
                                      }} to="/service/ectopicPregnancy" className="footinfo cenmenmob">
                                <div className="arrowicon">
                                    <LazyLoadImage className="arrowbar" src="../photo/arrow-right.png"/>
                                </div>
                                <h6>Ectopic Pregnancy</h6>
                            </Link>
                            <Link onClick={()=>{
                                        setServiceseta("Pre-Eclampsia")
                                        props.setFirst("none")
                                        props.setTop("block")
                                        props.setThree("none")
                                      }} to="/service/preEclampsia" className="footinfo cenmenmob">
                                <div className="arrowicon">
                                    <LazyLoadImage className="arrowbar" src="../photo/arrow-right.png"/>
                                </div>
                                <h6>Pre-Eclampsia</h6>
                            </Link>
                            <Link onClick={()=>{
                                        setServiceseta("Inevitable Abortion")
                                        props.setFirst("none")
                                        props.setTop("block")
                                        props.setThree("none")
                                      }} to="/service/inevitableAbortion" className="footinfo cenmenmob">
                                <div className="arrowicon">
                                    <LazyLoadImage className="arrowbar" src="../photo/arrow-right.png"/>
                                </div>
                                <h6>Inevitable Abortion</h6>
                            </Link>
                            <Link onClick={()=>{
                                        setServiceseta("Respiratory Problems With Pregnancy")
                                        props.setFirst("none")
                                        props.setTop("block")
                                        props.setThree("none")
                                      }} to="/service/respiratory" className="footinfo cenmenmob">
                                <div className="arrowicon">
                                    <LazyLoadImage className="arrowbar" src="../photo/arrow-right.png"/>
                                </div>
                                <h6>Respiratory Problems With Pregnancy</h6>
                            </Link>
                            <Link onClick={()=>{
                                        setServiceseta("Orthopedic Problem With Pregnancy")
                                        props.setFirst("none")
                                        props.setTop("block")
                                        props.setThree("none")
                                      }} to="/service/orthopedicProblem" className="footinfo cenmenmob">
                                <div className="arrowicon">
                                    <LazyLoadImage className="arrowbar" src="../photo/arrow-right.png"/>
                                </div>
                                <h6>Orthopedic Problem With Pregnancy</h6>
                            </Link>
                            <Link onClick={()=>{
                                        setServiceseta("Infections Disease")
                                        props.setFirst("none")
                                        props.setTop("block")
                                        props.setThree("none")
                                      }} to="/service/infectionsDisease" className="footinfo cenmenmob">
                                <div className="arrowicon">
                                    <LazyLoadImage className="arrowbar" src="../photo/arrow-right.png"/>
                                </div>
                                <h6>Infections Disease</h6>
                            </Link>
                            <Link onClick={()=>{
                                        setServiceseta("Hysterectomy")
                                        props.setFirst("none")
                                        props.setTop("block")
                                        props.setThree("none")
                                      }} to="/service/hysterectomy" className="footinfo cenmenmob">
                                <div className="arrowicon">
                                    <LazyLoadImage className="arrowbar" src="../photo/arrow-right.png"/>
                                </div>
                                <h6>Hysterectomy</h6>
                            </Link>
                        </div>
                    </div>
            </div>
        </div>
        <button onClick={thrreder} className="footinfo backback">
                                <div className="arrowicon mobicocrn">
                                    <LazyLoadImage className="arrowbar" src="../photo/error.png"/>
                                </div>
                                <h6>Back</h6>
                            </button>
        </div>
        </>)
}
export default memo(Mobthreelist);
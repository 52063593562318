import { memo, useContext } from "react"
import "../../node_modules/bootstrap/dist/css/bootstrap.min.css"
import "./inner.css"
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Link } from "react-router-dom";
import { data1 } from "../App";

function Mobsecondlist(props){
    const setServiceseta=useContext(data1);
    const changer={
        display:`${props.second}`
    }
    function seconder(){
        props.setTop("block")
        props.setSecond("none")
        props.setThree("none")
        props.setFour("none")
    }
    return (<>
        <div style={changer}>
        <div className="mobbag">
            <div className="row">
            <div className="col-lg-3 col-md-6">
                        <div>
                        <button className="footinfo backback">
                                <div className="arrowicon mobicocrn">
                                    <LazyLoadImage className="arrowbar" src="../photo/joint.png"/>
                                </div>
                                <h6>Orthopedic Services</h6>
                            </button>
                            <Link to="/service/boneFracture" onClick={()=>{
                                        setServiceseta("Bone Fracture Treatment Surgical And Conservative Treatment")
                                        props.setFirst("none")
                                        props.setTop("block")
                                        props.setSecond("none")
                                      }} className="footinfo cenmenmob">
                                <div className="arrowicon">
                                    <LazyLoadImage className="arrowbar" src="../photo/arrow-right.png"/>
                                </div>
                                <h6>Bone Fracture Treatment Surgical And Conservative Treatment</h6>
                            </Link>
                            <Link to="/service/sportMedicine" onClick={()=>{
                                        setServiceseta("Sport Medicine")
                                        props.setFirst("none")
                                        props.setTop("block")
                                        props.setSecond("none")
                                      }} className="footinfo cenmenmob">
                                <div className="arrowicon">
                                    <LazyLoadImage className="arrowbar" src="../photo/arrow-right.png"/>
                                </div>
                                <h6>Sport Medicine</h6>
                            </Link>
                            <Link onClick={()=>{
                                        setServiceseta("Arthroscopic Acl Andmenicus Repair Reconstruction")
                                        props.setFirst("none")
                                        props.setTop("block")
                                        props.setSecond("none")
                                      }} to="/service/arthroscopicAcl"  className="footinfo cenmenmob">
                                <div className="arrowicon">
                                    <LazyLoadImage className="arrowbar" src="../photo/arrow-right.png"/>
                                </div>
                                <h6>Arthroscopic Acl Andmenicus Repair Reconstruction</h6>
                            </Link>
                            <Link onClick={()=>{
                                        setServiceseta("Arthroscopic Shoulder Surgeries")
                                        props.setFirst("none")
                                        props.setTop("block")
                                        props.setSecond("none")
                                      }} to="/service/arthroscopicShoulder"  className="footinfo cenmenmob">
                                <div className="arrowicon">
                                    <LazyLoadImage className="arrowbar" src="../photo/arrow-right.png"/>
                                </div>
                                <h6>Arthroscopic Shoulder Surgeries</h6>
                            </Link>
                            <Link onClick={()=>{
                                        setServiceseta("Back Pain And Disc Related Diseases Treatment")
                                        props.setFirst("none")
                                        props.setTop("block")
                                        props.setSecond("none")
                                      }} to="/service/backPain"  className="footinfo cenmenmob">
                                <div className="arrowicon">
                                    <LazyLoadImage className="arrowbar" src="../photo/arrow-right.png"/>
                                </div>
                                <h6>Back Pain And Disc Related Diseases Treatment</h6>
                            </Link>
                            <Link onClick={()=>{
                                        setServiceseta("Knee And Hip Replacement Surgery Primary And Revision")
                                        props.setFirst("none")
                                        props.setTop("block")
                                        props.setSecond("none")
                                      }} to="/service/kneeAndHip"  className="footinfo cenmenmob">
                                <div className="arrowicon">
                                    <LazyLoadImage className="arrowbar" src="../photo/arrow-right.png"/>
                                </div>
                                <h6>Knee And Hip Replacement Surgery Primary And Revision</h6>
                            </Link>
                            <Link onClick={()=>{
                                        setServiceseta("Diabetic Wound Dressing And Management")
                                        props.setFirst("none")
                                        props.setTop("block")
                                        props.setSecond("none")
                                      }} to="/service/diabeticWound"  className="footinfo cenmenmob">
                                <div className="arrowicon">
                                    <LazyLoadImage className="arrowbar" src="../photo/arrow-right.png"/>
                                </div>
                                <h6>Diabetic Wound Dressing And Management</h6>
                            </Link>
                        </div>
                    </div>
            </div>
        </div>
        <button onClick={seconder} className="footinfo backback">
                                <div className="arrowicon mobicocrn">
                                    <LazyLoadImage className="arrowbar" src="../photo/error.png"/>
                                </div>
                                <h6>Back</h6>
                            </button>
        </div>
        </>)
}
export default memo(Mobsecondlist);
import logo from './logo.svg';
import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Home from './Components/Home/Home';
import About from './Components/About-Us/About';
import Ourdoctor from './Components/OurDoctor/Ourdoctor';
import Service from './Components/Mainservice/Service';
import { createContext, useState } from 'react';
import Contactus from './Components/ContactUs/Contactus';
import Gallery from './Components/Gallery/Gallery';
import Twodoctors from './Components/OurDoctor/Twodoctors';
import BoneFracture from './Components/Mainservice/BoneFracture';
import SportMedicine from './Components/Mainservice/SportMedicine';
import ArthroscopicAcl from './Components/Mainservice/ArthroscopicAcl';
import ArthroscopicShoulder from './Components/Mainservice/ArthroscopicShoulder';
import BackPain from './Components/Mainservice/BackPain';
import KneeAndHip from './Components/Mainservice/KneeAndHip';
import DiabeticWound from './Components/Mainservice/DiabeticWound';
import GeneralObstetrics from './Components/Mainservice/GeneralObstetrics';
import HighRiskObstetric from './Components/Mainservice/HighRiskObstetric';
import PIH from './Components/Mainservice/PIH';
import APH from './Components/Mainservice/APH';
import PPH from './Components/Mainservice/PPH';
import EctopicPregnancy from './Components/Mainservice/EctopicPregnancy';
import PreEclampsia from './Components/Mainservice/PreEclampsia';
import InevitableAbortion from './Components/Mainservice/InevitableAbortion';
import Respiratory from './Components/Mainservice/Respiratory';
import OrthopedicProblem from './Components/Mainservice/OrthopedicProblem';
import InfectionsDisease from './Components/Mainservice/InfectionsDisease';
import Hysterectomy from './Components/Mainservice/Hysterectomy';
import Laboratory from './Components/Mainservice/Laboratory';
import Wounddressing from './Components/Mainservice/Wounddressing';
import Highintensity from './Components/Mainservice/Highintensity';

const data=createContext();
const data1=createContext();
function App() {
  const [serviceset, setServiceset]=useState("Bone Fracture Treatment Surgical And Conservative Treatment")
  return (
  <data.Provider value={serviceset}>
    <data1.Provider value={setServiceset}>
      <BrowserRouter>
        <Routes>
          <Route path='/' element={<Home/>}></Route>
          <Route path='/about' element={<About/>}></Route>
          <Route path='/drchethankumarr' element={<Ourdoctor/>}></Route>
          <Route path='/drkrupankabv' element={<Twodoctors/>}></Route>
          <Route path='/service' element={<Service/>}>
                 <Route path='/service/boneFracture' element={<BoneFracture/>}></Route>
                 <Route path='/service/sportMedicine' element={<SportMedicine/>}></Route>
                 <Route path='/service/arthroscopicAcl' element={<ArthroscopicAcl/>}></Route>
                 <Route path='/service/arthroscopicShoulder' element={<ArthroscopicShoulder/>}></Route>
                 <Route path='/service/backPain' element={<BackPain/>}></Route>
                 <Route path='/service/kneeAndHip' element={<KneeAndHip/>}></Route>
                 <Route path='/service/diabeticWound' element={<DiabeticWound/>}></Route>
                 <Route path='/service/generalObstetrics' element={<GeneralObstetrics/>}></Route>
                 <Route path='/service/highRiskObstetric' element={<HighRiskObstetric/>}></Route>
                 <Route path='/service/pih' element={<PIH/>}></Route>
                 <Route path='/service/aph' element={<APH/>}></Route>
                 <Route path='/service/pph' element={<PPH/>}></Route>
                 <Route path='/service/ectopicPregnancy' element={<EctopicPregnancy/>}></Route>
                 <Route path='/service/preEclampsia' element={<PreEclampsia/>}></Route>
                 <Route path='/service/inevitableAbortion' element={<InevitableAbortion/>}></Route>
                 <Route path='/service/respiratory' element={<Respiratory/>}></Route>
                 <Route path='/service/orthopedicProblem' element={<OrthopedicProblem/>}></Route>
                 <Route path='/service/infectionsDisease' element={<InfectionsDisease/>}></Route>
                 <Route path='/service/hysterectomy' element={<Hysterectomy/>}></Route>
                 <Route path='/service/highintensity' element={<Highintensity/>}></Route>
                 <Route path='/service/laboratory' element={<Laboratory/>}></Route>
                 <Route path='/service/wounddressing' element={<Wounddressing/>}></Route>
            
          </Route>
          <Route path='/contact' element={<Contactus/>}></Route>
          <Route path='/gallery' element={<Gallery/>}></Route>
        </Routes>
      </BrowserRouter>
    </data1.Provider>
  </data.Provider>
  );
}

export default App;
export {data, data1}

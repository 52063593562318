import { memo, useEffect } from "react";
import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import ".././inner.css";
import { LazyLoadImage } from "react-lazy-load-image-component";

function InfectionsDisease() {
  useEffect(() => {
    document.title = "Infectious disease treatment in puttenahalli";
    const newMetaDescription =
      "We have the best doctor for the best Infectious disease treatment in puttenahalli. Feel free to visit us today.";
    const metaTag = document.querySelector('meta[name="description"]');
    if (metaTag) {
      metaTag.setAttribute("content", newMetaDescription);
    }
  }, []);
  return (
    <>
      <h2 style={{ fontSize: "0px" }}>
        Infectious disease treatment in puttenahalli
      </h2>
      <div className="serinfobox">
        <div className="serinfoimg">
          <LazyLoadImage src="../photo/serx.jpg" />
        </div>
        <div className="textareaofser">
          <h4>Infections Disease</h4>
          <p>
            Infectious diseases are caused by pathogenic microorganisms such as
            bacteria, viruses, fungi, or parasites. These microorganisms can
            enter the body and multiply, leading to illness and various
            symptoms. Common infectious diseases include:
          </p>
          <h6>Influenza (Flu):</h6>
          <p>
            The flu is a viral respiratory infection that causes symptoms such
            as fever, cough, sore throat, body aches, and fatigue. It can spread
            easily from person to person through respiratory droplets.
          </p>
          <h6>Common Cold:</h6>
          <p>
            The common cold is a viral infection that affects the upper
            respiratory system, causing symptoms like a runny or stuffy nose,
            sneezing, coughing, and mild fatigue. It is usually caused by
            rhinoviruses or other respiratory viruses.
          </p>
          <h6>Urinary Tract Infection (UTI):</h6>
          <p>
            UTIs occur when bacteria, most commonly Escherichia coli (E. coli),
            enter the urinary tract and cause infection. Symptoms can include a
            frequent urge to urinate, a burning sensation during urination,
            cloudy or bloody urine, and lower abdominal pain.
          </p>
          <h6>Gastroenteritis:</h6>
          <p>
            {" "}
            Gastroenteritis, commonly known as the stomach flu, is inflammation
            of the stomach and intestines typically caused by viral or bacterial
            infections. It leads to symptoms like diarrhea, nausea, vomiting,
            abdominal cramps, and sometimes fever.
          </p>
          <h6>Pneumonia: </h6>
          <p>
            {" "}
            Pneumonia is an infection that affects the lungs, causing
            inflammation and difficulty breathing. It can be caused by bacteria,
            viruses, or fungi. Symptoms may include cough, chest pain, fever,
            chills, and shortness of breath.
          </p>
          <h6>Sexually Transmitted Infections (STIs): </h6>
          <p>
            STIs are infections that are primarily transmitted through sexual
            contact. Common STIs include chlamydia, gonorrhea, syphilis, human
            papillomavirus (HPV), herpes, and human immunodeficiency virus
            (HIV). Each STI has its own set of symptoms and complications.
          </p>
          <h6>Tuberculosis (TB):</h6>
          <p>
            Tuberculosis is a bacterial infection caused by Mycobacterium
            tuberculosis. It primarily affects the lungs but can also affect
            other parts of the body. Symptoms may include coughing, chest pain,
            weight loss, fatigue, and night sweats.
          </p>
          <p>
            Prevention and treatment for infectious diseases depend on the
            specific infection. Generally, measures like good hygiene practices
            (handwashing, covering mouth and nose when coughing or sneezing),
            vaccination, safe sexual practices, and avoiding close contact with
            infected individuals can help reduce the risk of infection.
            Treatment may involve antiviral medications, antibiotics, antifungal
            drugs, or specific therapies depending on the infection.
          </p>
          <p>
            If you suspect you have an infectious disease, it is important to
            consult a healthcare professional for an accurate diagnosis and
            appropriate treatment. They can provide guidance, prescribe
            medications if necessary, and monitor your condition for a safe
            recovery. We have the best doctor for the best Infectious disease
            treatment in puttenahalli. Feel free to visit us today.
          </p>
        </div>
      </div>
    </>
  );
}
export default memo(InfectionsDisease);

import { memo, useEffect, useState } from "react"
import "../../../../node_modules/bootstrap/dist/css/bootstrap.min.css"
import "../.././inner.css"
import OrthopedicDoctor from "./OrthopedicDoctor"
import Obgpedicinfo from "./Obgdoctor"

function Doctorinfo(){
    const [number, setNumber]=useState(0);
    const [one, setOne]=useState("block");
    const [two, setTwo]=useState("none")
    const [check, setCheck]=useState(true)
    useEffect(()=>{
        const timer=setInterval(()=>{
            if(check==true){
                if(number<1){
                    setNumber(1);
                    setOne("block")
                    setTwo("none")
                }else{
                    setNumber(0);
                    setOne("none")
                    setTwo("block")
                }
            }
        },10000)
        return ()=>{
            clearInterval(timer)
        }
    })
    return (
        <>
        <section className="sectionma">
            <OrthopedicDoctor one={one} setCheck={setCheck} setOne={setOne} setTwo={setTwo}/>
            <Obgpedicinfo two={two} setCheck={setCheck} setOne={setOne} setTwo={setTwo}/>
        </section>
        </>
    )
}
export default memo(Doctorinfo)
import { memo, useState } from "react"
import HeaderTop from "./HeaderTop"
import Headermenu from "./Headermenu"
import Mobilemenu from "./Mobilemenu"
import "../../node_modules/bootstrap/dist/css/bootstrap.min.css"
import "./inner.css"

function Mobmenu(){
    const [first, setFirst]=useState("none");
    return (<>
        <Mobilemenu first={first} setFirst={setFirst}/>
        <HeaderTop first={first} setFirst={setFirst}/>
        <Headermenu first={first} setFirst={setFirst}/>
    </>)
}
export default memo(Mobmenu);
import { useEffect } from "react";
import Addressinfo from "../Mainbox/Addressinfo";
import ClinicServices from "../Mainbox/ClinicServices";
import ContactButton from "../Mainbox/ContactButton/ContactButton";
import Mobcontroller from "../Mainbox/ContactButton/Mobcontroller";
import Contactbox from "../Mainbox/Contactbox";
import Doctorinfo from "../Mainbox/Doctorinfo/Doctorinfo";
import Footer from "../Mainbox/Footer";
import Hospitalinfo from "../Mainbox/Hospitalinfo";
import Obgservices from "../Mainbox/Obgservices";
import Orthopedicser from "../Mainbox/Orthopedicser";
import Review from "../Mainbox/Review";
import Slider from "../Mainbox/Slider";
import Whychooseus from "../Mainbox/Whychooseus";
import Mobmenu from "../Mobmenu";
import Scrolltop from "../Scrolltop";

function Home() {
  useEffect(() => {
    window.scrollTo({ top: "0px" });

    document.title = "Best multispecialty hospital in JP Nagar";
    const newMetaDescription =
      "Looking for the best multispecialty hospital in JP Nagar? Then look no fur and visit us today.";
    const metaTag = document.querySelector('meta[name="description"]');
    if (metaTag) {
      console.log(metaTag);
      metaTag.setAttribute("content", newMetaDescription);
    }
  }, []);
  return (
    <>
      <Scrolltop />
      <Mobcontroller />
      <ContactButton />
      <Mobmenu />
      <Slider />
      <Addressinfo />
      <Contactbox />
      <Hospitalinfo />
      <Orthopedicser />
      <ClinicServices />
      <Obgservices />
      <Whychooseus />
      <Doctorinfo />
      <Review />
      <Footer />
    </>
  );
}
export default Home;
